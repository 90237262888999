/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS420M
 * 화면 설명: 전자서명사전입력메인화면
 */
<template> 

  <ur-page-container class="msp" title="전자서명" :show-title="true" ref="page" @on-header-left-click="fn_HeaderBackBtnHandler" type="subpage">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="pb50">
        <ur-box-container alignH="between" alignV="center" componentid="" direction="row" class="pal1624">
          <span class="fs22rem fwb" v-if="currentStep === 0 || currentStep === 1">계약체결•이행 동의</span>
          <span class="fs22rem fwb" v-if="currentStep === 2">계약관계자 정보 확인</span>
          <span class="fs22rem fwb" v-if="currentStep === 3">계좌선택</span>
          <span class="fs22rem fwb" v-if="currentStep === 4">질병정보확인</span>
          <div>
            <span class="fs20rem crTy-blue2 fwb mr2" v-if="currentStep === 0 || (currentStep === 1 && !agree)">1</span>
            <span class="fs20rem crTy-blue2 fwb mr2" v-if="currentStep === 1 && agree">2</span>
            <span class="fs20rem crTy-blue2 fwb mr2" v-if="currentStep === 2">3</span>
            <span class="fs20rem crTy-blue2 fwb mr2" v-if="currentStep === 3">4</span>
            <span class="fs20rem crTy-blue2 fwb mr2" v-if="currentStep === 4">5</span>
            /<span class="ml2" v-if="nundwYn === 'N'">{{(this.lv_InputData.itemData?2:4)}}</span>
            <span class="ml2" v-else>{{(this.lv_InputData.itemData?2:5)}}</span>
          </div>
        </ur-box-container>
        
        <div v-if="currentStep === 2" class="bd-T-Ty1 bd-b-Ty1 bgcolor-7 w100 pal1524" >
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li> 변경한 정보는 고객카드에 반영됩니다.</li>
          </ul>
        </div>
        <ur-box-container v-else alignH="between" alignV="center" componentid="" direction="row" class="bd-T-Ty1 h10px bgcolor-3"></ur-box-container>

        <component :is="currentView" :scrollId="scrollId" @move="fn_MoveEvent" :commData="lv_CommData"></component>
      </ur-box-container>
    </ur-box-container>
    <!-- <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-tab-box">  
       <ur-box-container alignV="start" direction="column" class="width-btn">
          <ur-box-container alignV="start" direction="column" class="msp-step-type">
            <div class="ing-content">
              <div class="ing-item" :class="[{'checked' : currentStep === 1 && !agree},{'completed' : (currentStep === 1 && agree) || currentStep === 2 || currentStep === 3}]">
                <span class="step">1</span>
                <mo-checkbox size="small"  class="com-check" checked="" />
                <span class="list-step">동의</span>
              </div>
              <div class="ing-item" :class="[{'checked' : currentStep === 1 && agree},{'completed' : currentStep === 2 || currentStep === 3}]">
                <span class="step">2</span>
                <mo-checkbox size="small"  class="com-check" checked="" />
                <span class="list-step">인증</span>
              </div>
              <div class="ing-item" :class="[{'checked' : currentStep === 2},{'completed' : currentStep === 3}]">
                <span class="step">3</span>
                <mo-checkbox size="small"  class="com-check" checked="" />
                <span class="list-step">계약관계자 정보확인</span>
              </div>
              <div class="ing-item" :class="[{'checked' : currentStep === 3}]">
                <span class="step">4</span>
                <mo-checkbox size="small"  class="com-check" checked="" />
                <span class="list-step">계좌</span>
              </div>
            </div>
          </ur-box-container>
        </ur-box-container>
        <component :is="currentView" @move="fn_MoveEvent" :commData="lv_CommData"></component>
    </ur-box-container> -->
  </ur-page-container> 
   
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/

import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSElstUtil from '@/ui/ps/comm/PSElstUtil'
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil'
import elstPrgStatVO from '@/ui/ps/vo/psElstPrgStatVO'
import befrInptInfoVO from '@/ui/ps/vo/psBefrInptInfoVO'
import piCommonUtil from '@/ui/pi/common/piCommonUtil'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import MSPPS421D from '@/ui/ps/MSPPS421D'
import MSPPS422D from '@/ui/ps/MSPPS422D'
import MSPPS423D from '@/ui/ps/MSPPS423D'
import MSPPS424D from '@/ui/ps/MSPPS424D'
import moment from 'moment'

export default {

/***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS420M', 
  screenId: 'MSPPS420M',

  components: {
    'MSPPS421D' : MSPPS421D,  // 전자서명 인증
    'MSPPS422D' : MSPPS422D,  // 전자서명 - 계약관계자 정보확인
    'MSPPS423D' : MSPPS423D,  // 전자서명 - 계좌선택
    'MSPPS424D' : MSPPS424D  // 전자서명 - 선심사고지정보확인
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {            
      agree: false,
      pId: '', // 설계ID
      lv_InputData: {}, // 받아온 데이터
      lv_HoshInfoList: [], // 조회해온 데이터
      lv_CommData: {},
      lv_NewSignFlag: false, // 신규(true) or 재발행(false) flag
      lv_dpstrInfo: {}, // 191217 예금주정보
      initDataSaveFlag1: false, // 초기 적재 데이터 완료 플래그 TTSD00001DEM
      initDataSaveFlag2: false, // 초기 적재 데이터 완료 플래그 TTSD00002DEM
      currentStep: 0,
      currentView: '',
      standardAge: 19, // 미성년자 기준 나이
      viewList: [null, MSPPS421D, MSPPS422D, MSPPS423D, MSPPS424D, null],
      setpList: [
        {key: '1', status: 'done'},
        {key: '2', status: 'done'},
        {key: '3', status: ''}
      ],
      inputPath: '', // 화면 유입 경로 - '':사랑온, '210M':중단건조회
      isWalkthroughs: false, // Walkthroughs화면 띄울지 여부
      isInsrdMinor: false, // 피보험자 미성년 구분
      insuredPerson: false, // 피보험자가 있는지 여부 - true:계피상이, false:계피동일
      positiveButtonLabel: '재시도',
      negativeButtonLabel: '취소',
      scrollId : '',
      alertMsg: '전자서명 구성 중 오류가 발생하였습니다.<br />재시도하시겠습니까?', // 팝업 메시지 내용
      nundwYn: 'N', // 선심사여부 (선심사PJT 추가(2024-01-29))
      numNundwOpin: 0 // 현재 질병상세입력하는 순번 - 선심사 고지의견 입력을 출력해야 하는 순번 (선심사PJT 추가(2024-02-29))
    }
  },//data


/***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/
  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate
  
  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {    
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

    /*
    if (this.$parent && this.$parent.$children) {
      var children = this.$parent.$children
      for (let i = 0; i < children.length; i++) {
        let child = children[i]
        // children 중에 inactive 상태인 component가 있는지 확인
        if (child._inactive && child.$options.name === this.$options.name) {
          child.$destroy()
        } // end if
      } // end for i
    } // end if
    */
    // 사랑온 받아온 데이타
    if (this.$route.params) {
      this.lv_InputData = this.$route.params
    }
    // 계약자, 피보험자, 수익자, 상품 정보 가져오기
    if (this.lv_InputData.itemData) { // TSSPS210M(설계청약홈) 에서 페이지 이동일때
      this.lv_CommData.contrElstYn = this.lv_InputData.itemData.contrElstYn.trim() || 'N'
      this.lv_CommData.insrdElstYn = this.lv_InputData.itemData.insrdElstYn.trim() || 'N'
      this.lv_CommData.dpstrElstYn = this.lv_InputData.itemData.dpstrElstYn.trim() || 'N'
      // 2020.02.21 : 계약관계자 서명완료시, 계약자활성화 로직 이동 (TSSPS121D.vue > 6. FC외에 모든 관계자 서명확인)
      // 계약자, 피보험자는 인증이 되었는데 종피,FC... 이 인증안되었을때 계약자만 인증받는 프로세스로 진행한다
      // if (this.lv_CommData.contrElstYn === 'Y' && this.lv_CommData.insrdElstYn === 'Y') { // 계약자/피보험자 모두 서명완료
      //   this.lv_CommData.contrElstYn = 'N' // 계약자 인증 받는 로직
      // }
      this.pId = this.lv_InputData.itemData.elstPlanId
      // 사랑온에서 넘어온것처럼 데이터 가공
      console.log('MSPPS420M this.lv_InputData ::::' + JSON.stringify(this.lv_InputData))
      this.lv_InputData.entplId = this.lv_InputData.itemData.elstPlanId // 설계아이디
      this.lv_InputData.fullFilePath = this.lv_InputData.itemData.fullFilePath || '' // 210M 중단건에서 넘어올때 PDF 파일 경로
      this.lv_InputData.ozdFilePath = this.lv_InputData.itemData.fullFilePath || '' // 210M 중단건에서 넘어올때 OZD
      this.lv_InputData.moDCDocuMngVO = {}
      this.lv_InputData.moDCDocuMngVO.eltrnDoctId = this.lv_InputData.itemData.vuchId // 영수증아이디
      this.lv_InputData.moDCDocuMngVO.moblBusnScCd = 'SE' // 모바일업무구분코드
      this.lv_InputData.moDCDocuMngVO.regntEno = PSServiceManager.getUserInfo('userId')
      this.inputPath = '210M'
      // 중단건에서 넘어올때 아래 데이터 셋팅
      this.lv_CommData.insrdTrtyFgr = this.lv_InputData.itemData.insrdTrtyFgr // 피보험자특약수
      this.lv_CommData.aisdTrtyFgr = this.lv_InputData.itemData.aisdTrtyFgr // 종피보험자특약수
      this.lv_CommData.secusFrmCd = this.lv_InputData.itemData.zzSecusFrmId // 증권양식값
      this.lv_CommData.untyCovrInsrTypCd = this.lv_InputData.itemData.zzUntyCoITId // 통합보장보험유형값
      this.lv_CommData.ptclrPrdtTypCd = this.lv_InputData.itemData.zzPtclrPrdTId // 특이상품유형값
      this.lv_CommData.pdinsRCCId = this.lv_InputData.itemData.zzPdinsRCCId // 상품보험관계구성코드
      this.lv_CommData.kliaInsrScCd = this.lv_InputData.itemData.zzKliaPrdtId // 생보협회상품분류값
      this.lv_CommData.coprClctYn = this.lv_InputData.itemData.coprClctYn || ' ' // 공동모집여부
      this.lv_CommData.contrInsrdSameYn = this.lv_InputData.itemData.contrInsrdSameYn // 계피상이여부
      this.lv_CommData.contrElstYmd = this.lv_InputData.itemData.contrElstYmd // 계약자 전자서명일
      this.lv_CommData.insrdElstYmd = this.lv_InputData.itemData.insrdElstYmd // 피보험자 전자서명일
      // 191217 예금주 정보 초기화
      this.lv_InputData.lv_dpstr = {chnlCustId: ''}
      this.lv_CommData.insrTypMgId = this.lv_InputData.itemData.zzInsrTypMgId // 보험유형관리코드RD (ASR210500282_2021.05.31 PJO)
      this.lv_CommData.prdtTypMgId = this.lv_InputData.itemData.zzPrdtTypMgId // 상품유형관리코드RD (ASR220400794_22.05.10 PJO)
      this.lv_CommData.prmPadTVId = this.lv_InputData.itemData.prmPadTVId // 보험료납입유형값RD (ASR240400333_24.05 PJO/장한나)
      console.log('MSPPS420M.created......2.중단건에서진입' )
    } else { // 사랑온에서 페이지 이동일때
      this.pId = this.lv_InputData.entplId
      // 가입설계에서 넘어올때 아래 데이터 셋팅
      this.lv_CommData.insrdTrtyFgr = (this.lv_InputData.insrdTrtyFgr === null || this.lv_InputData.insrdTrtyFgr === '') ? '0' : String(this.lv_InputData.insrdTrtyFgr) // 피보험자특약수
      this.lv_CommData.aisdTrtyFgr = (this.lv_InputData.aisdTrtyFgr === null || this.lv_InputData.aisdTrtyFgr === '') ? '0' : String(this.lv_InputData.aisdTrtyFgr) // 종피보험자특약수
      this.lv_CommData.secusFrmCd = (this.lv_InputData.zzSecusFrmId === undefined || this.lv_InputData.zzSecusFrmId === '') ? ' ' : this.lv_InputData.zzSecusFrmId // 증권양식값
      this.lv_CommData.untyCovrInsrTypCd = (this.lv_InputData.zzUntyCoITId === undefined || this.lv_InputData.zzUntyCoITId === '') ? ' ' : this.lv_InputData.zzUntyCoITId // 통합보장보험유형값
      this.lv_CommData.ptclrPrdtTypCd = (this.lv_InputData.zzPtclrPrdTId === undefined || this.lv_InputData.zzPtclrPrdTId === '') ? ' ' : this.lv_InputData.zzPtclrPrdTId // 특이상품유형값
      this.lv_CommData.pdinsRCCId = (this.lv_InputData.zzPdinsRCCId === undefined || this.lv_InputData.zzPdinsRCCId === '') ? ' ' : this.lv_InputData.zzPdinsRCCId // 상품보험관계구성코드
      this.lv_CommData.kliaInsrScCd = (this.lv_InputData.zzKliaPrdtId === undefined || this.lv_InputData.zzKliaPrdtId === '') ? ' ' : this.lv_InputData.zzKliaPrdtId // 생보협회상품분류값
      this.lv_CommData.coprClctYn = this.lv_InputData.coprClctYn ? 'Y' : 'N' // 공동모집여부
      // 생보협회상품분류값이 3자리로 넘어오면 첫번째를 자르고 뒤에 두개의 유효값만 사용한다
      this.lv_CommData.kliaInsrScCd = (typeof (this.lv_CommData.kliaInsrScCd) === 'string') ? String(Number(this.lv_CommData.kliaInsrScCd)) : String(this.lv_CommData.kliaInsrScCd)
      if (this.lv_CommData.kliaInsrScCd.length > 2) {
        this.lv_CommData.kliaInsrScCd = this.lv_CommData.kliaInsrScCd.substr(1, 2)
      } else if (this.lv_CommData.kliaInsrScCd.length === 0 || this.lv_CommData.kliaInsrScCd === '0') {
        this.lv_CommData.kliaInsrScCd = ' '
      } else if (this.lv_CommData.kliaInsrScCd.length === 1) {
        this.lv_CommData.kliaInsrScCd = '0' + this.lv_CommData.kliaInsrScCd
      }
      this.lv_CommData.insrTypMgId = (this.lv_InputData.zzInsrTypMgId === undefined || this.lv_InputData.zzInsrTypMgId === '') ? ' ' : this.lv_InputData.zzInsrTypMgId // 보험유형관리코드RD (ASR210500282_2021.05.31 PJO)
      this.lv_CommData.prdtTypMgId = (this.lv_InputData.zzPrdtTypMgId === undefined || this.lv_InputData.zzPrdtTypMgId === '') ? ' ' : this.lv_InputData.zzPrdtTypMgId // 상품유형관리코드RD (ASR220400794_22.05.10 PJO)
      this.lv_CommData.prmPadTVId = (this.lv_InputData.zzPrmPadTVId === undefined || this.lv_InputData.zzPrmPadTVId === '') ? ' ' : this.lv_InputData.zzPrmPadTVId // 보험료납입유형값RD (ASR240400333_24.05 PJO/장한나)
      console.log('MSPPS420M.created......1.진입' )
    }
    console.log('MSPPS420M.보험유형관리코드RD[' + this.lv_CommData.insrTypMgId + ']' )
    console.log('MSPPS420M.상품유형관리코드RD[' + this.lv_CommData.prdtTypMgId + ']' )
    console.log('MSPPS420M.보험료납입유형값RD[' + this.lv_CommData.prmPadTVId + ']' )

    // 사랑온에서 넘어온 USER ID와 비교하여 상이 일때 처리
    //if (this.lv_InputData.moDCDocuMngVO.regntEno === this.$MenuManager.fn_GetUserInfoProp('userId')) {
    if (this.lv_InputData.moDCDocuMngVO.regntEno === PSServiceManager.getUserInfo('userId')) {
      if (this.pId === '') {
        this.getStore('confirm').dispatch('ADD', '선택된 설계ID가 없습니다')
      } else {
        // 191217 예금주추가
        // PSElstUtil에서 예금주정보조회를 위해서, vuchId, inputPath를 전달함
        // let jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: this.pId}})
        let jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: this.pId}}, vuchId: this.lv_InputData.moDCDocuMngVO.eltrnDoctId, dpstrInfo: this.lv_InputData.lv_dpstr})        
        PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', jsonString, this.fn_ServiceDataResultHandler)
        this.fn_NundwData() // 선심사 정보 조회 (선심사PJT 추가 - 2024.02.05)
      }
    } else {
      this.getStore('confirm').dispatch('ADD', '사용자 정보가 상이 합니다.<br />재로그인 해주세요')
    }
    // 200109 : STORAGE_ROOT_PATH 조회
    PSServiceManager.getStorageRootPath()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.scrollId = PSCommUtil.fn_GetPageContainerScrollId(this.$refs.page)
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
    // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { return this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    currentStep (newValue) {
      this.currentView = this.viewList[newValue]
      
      //tabBox 탭페이지 설정
      // if (newValue >= 1 && newValue <= 3) {
      //   this.$refs.tabBox.setIdx(newValue.toString())
      // }
    },
    initDataSaveFlag1 (newValue) {
      if (newValue && this.initDataSaveFlag2) {
        this.lv_NewSignFlag = true
      } else {
        this.lv_NewSignFlag = false
      }
    },
    initDataSaveFlag2 (newValue) {
      if (newValue && this.initDataSaveFlag1) {
        this.lv_NewSignFlag = true
      } else {
        this.lv_NewSignFlag = false
      }
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {

  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/

    /******************************************************************************
    * Function명 : fn_ServiceDataResultHandler
    * 설명       : 전자서명 초기 데이타 호출 결과 컨트롤 함수
    ******************************************************************************/
    fn_ServiceDataResultHandler: function (event) {
      let hoshInfoList = this.lv_HoshInfoList = event.hoshInfoList // 계약자/피보험자 정보
      let dpstrInfo = this.lv_dpstrInfo = event.dpstrInfo // 191217 예금주 정보
      let eltrnSaveData = event.eltrnSaveData // 200110 전자서명정보

      if (hoshInfoList) {
        let hoshInfoList_11 = hoshInfoList.filter(( item ) => { return item.contvInsrdCd === '11' })[0] // 계약자
        let hoshInfoList_21 = hoshInfoList.filter(( item ) => { return item.contvInsrdCd === '21' })[0] // 주피자
        
        if (hoshInfoList_11.agtFileId !== hoshInfoList_21.agtFileId) { // 계피상이
          this.lv_CommData.userData1 = hoshInfoList_11
          if (hoshInfoList_21.custRrnId && this.$bizUtil.compareCurrentAge(hoshInfoList_21.custRrnId.substr(0, 6), this.standardAge)) { // 19세 미만이면 동의 받지 않음
            this.lv_CommData.userData2 = hoshInfoList_21
            this.lv_CommData.userData2.knb = this.lv_InputData.moDCDocuMngVO.moblBusnScCd // 업무구분(SE:청약)
          } else {
            this.lv_CommData.userData2 = {}
            this.isInsrdMinor = true // 피보험자가 미성년자인지 구분
          }
          this.lv_CommData.contrInsrdSameYn = 'N' // 계피상이
          this.insuredPerson = true // 계피상이
          let hoshInfoList_23 = hoshInfoList.filter(( item ) => { return item.contvInsrdCd === '23' })[0] // 종피자
          this.lv_CommData.userData4 = (piCommonUtil.isObj(hoshInfoList_23) && hoshInfoList_23.length > 0) ? hoshInfoList_23 : undefined // 종피가 있다면 종피 정보 넘겨줌
          // 피보험자가 미성년이거나 태아이고 계약자가 서명완료했을때 계약자 인증을 받아야 되니 계약자 서명 여부를 ''으로 변경하여 인증을 다시 받는다
          // if (this.inputPath === '210M' && (this.isInsrdMinor || this.lv_InputData.itemData.unchYn === 'Y') && this.lv_CommData.contrElstYn === 'Y') {
          //   this.lv_CommData.contrElstYn = 'N' // 서명여부를 ''으로 변경하여 계약자 인증을 다시 받게 한다
          // }
          // 20200226 예금주 단독일 경우
          if (this.inputPath === '210M' && (this.isInsrdMinor || this.lv_InputData.itemData.unchYn === 'Y')) {
            if (this.lv_CommData.contrElstYn === 'Y' && dpstrInfo.dpstrIdpnYn === 'N') {
              // 계약자 전자서명완료 && 피보험자가 미성년자일때 P
              this.lv_CommData.contrElstYn = 'N' // 서명여부를 ''으로 변경하여 계약자 인증을 다시 받게 한다
            }
          } // if (this.inputPath === '210M' && (this.isInsrdMinor || this.lv_InputData.itemData.unchYn === 'Y'))
        } else { // 계피동일
          // 200114 : 계약자 예금주 상이건 로직 추가
          // if (this.inputPath === '210M' && this.lv_CommData.contrElstYn === 'Y') { // 210M 에서 넘어오고 계약자 서명 완료했고 계피동일건일때
          //   this.lv_CommData.contrElstYn = 'N' // 서명여부를 ''으로 변경하여 계약자 인증을 다시 받게 한다
          // }
          this.lv_CommData.userData1 = hoshInfoList_11
          this.lv_CommData.userData2 = {}
          this.insuredPerson = false // 계피동일
          this.lv_CommData.contrInsrdSameYn = 'Y' // 계피동일
        }
        // userData 에 영수증 번호 셋팅( 휴대폰 인증 시 필요 )
        this.lv_CommData.userData1.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId // 휴대폰 인증시 필요한 영수증 번호 셋팅( 계약자 )
        if (Object.keys(this.lv_CommData.userData2).length > 0) {
          this.lv_CommData.userData2.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId // 휴대폰 인증시 필요한 영수증 번호 셋팅( 피보험자 )
        }
        this.lv_CommData.userData1.knb = this.lv_InputData.moDCDocuMngVO.moblBusnScCd // 업무구분(SE:청약)
        this.lv_CommData.userData3 = event.bnfrInfoList // 수익자정보
        this.lv_CommData.planInfo = event.planInfoData // 상품정보
        if (this.inputPath === '210M') {
          this.lv_InputData.moDCDocuMngVO.emailAddr = this.lv_CommData.userData1.detail.emailAddr // 이메일
          this.lv_InputData.moDCDocuMngVO.celno = this.lv_CommData.userData1.detail.celno // 휴대폰
        }
        // 받아온 데이터 local 저장, 첫페이지 add, 전자서명 진행상태 조회 및 저장, 상세 데이터 조회 및 저장
        this.lv_CommData.inputData = this.lv_InputData // 받아온 데이터
        this.lv_CommData.dpstrInfo = dpstrInfo // 191217, 예금주 정보
        this.lv_CommData.eltrnSaveData = eltrnSaveData // 200110 전자서명저장정보

        //나이 및 주민번호 마스킹
        if (this.lv_CommData.userData1.hasOwnProperty('custRrnId') && this.lv_CommData.userData1.custRrnId.length >= 7) {
          this.lv_CommData.userData1.custRrnId1 = this.lv_CommData.userData1.custRrnId.substring(0, 6) // 주민번호 앞자리
          this.lv_CommData.userData1.custRrnId2 = this.lv_CommData.userData1.custRrnId.substring(6, 7) + '******' // 주민번호 뒷 한자리 + 마스킹
        }
        if (this.lv_CommData.userData2.hasOwnProperty('custRrnId') && this.lv_CommData.userData2.custRrnId.length >= 7) {
          this.lv_CommData.userData2.custRrnId1 = this.lv_CommData.userData2.custRrnId.substring(0, 6) // 주민번호 앞자리
          this.lv_CommData.userData2.custRrnId2 = this.lv_CommData.userData2.custRrnId.substring(6, 7) + '******' // 주민번호 뒷 한자리 + 마스킹
        }
        if (this.lv_CommData.dpstrInfo.detail.hasOwnProperty('knb') && this.lv_CommData.dpstrInfo.detail.knb.length >= 7) {
          this.lv_CommData.dpstrInfo.custRrnId1 = this.lv_CommData.dpstrInfo.detail.knb.substring(0, 6) // 주민번호 앞자리
          this.lv_CommData.dpstrInfo.custRrnId2 = this.lv_CommData.dpstrInfo.detail.knb.substring(6, 7) + '******' // 주민번호 뒷 한자리 + 마스킹
          this.lv_CommData.dpstrInfo.age = PSDateUtil.getInsuranceAge(this.lv_CommData.dpstrInfo.detail.knb, moment(new Date().toISOString()).format('YYYYMMDD'))
        }
        
        this.currentStep = 1 // 첫번째 페이지 add
        this.fn_SelElstPrgStat(this.lv_InputData.moDCDocuMngVO.eltrnDoctId) // 전자서명 진행 상태 조회 -> 저장
        this.fn_SelElstDtlInfo(this.lv_InputData.moDCDocuMngVO.eltrnDoctId) // 전자서명 상세 데이터 조회 -> 저장

        // this.fn_NundwData()
      } else {
        // 계약자/피보험자 정보 호출 에러시 확인 팝업 띄우고 '예' 선택 하면 정보 재조회 한다
        this.fn_ShowAlert()
      }
    },
    /******************************************************************************
    * Function명 : fn_ShowAlert
    * 설명       : 전자서명 구성 오류시 재시도 메세지 팝업
    ******************************************************************************/
    fn_ShowAlert() {
      const lv_showAlert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: this.alertMsg,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_showAlert);
            
            let jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: this.pId}}, vuchId: this.lv_InputData.moDCDocuMngVO.eltrnDoctId, dpstrInfo: this.lv_InputData.lv_dpstr})
            PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', jsonString, this.fn_ServiceDataResultHandler)
            this.fn_NundwData() // 선심사 정보 조회 (선심사PJT 추가 - 2024.02.05)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_showAlert);
            //최근가입설계로 이동
            // this.$MenuManager.fn_RouterPushSrnId('MSPPI310M')
            this.$MenuManager.fn_RouterPushSrnId('MSPPI700M', {selTab: '1'})
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_SelElstPrgStat
    * 설명       : 전자서명 진행 상태 조회 함수 - TTSD00001 Table
    ******************************************************************************/
    fn_SelElstPrgStat (id) {
      let t_This = this
      let pParams = {vuchId: id}
      let trnstId = 'txTSSPS98S1'

      this.post(this, pParams, trnstId, 'S', {}, true)
        .then(function (response) {
          if (response.body && response.body.vuchId && response.body.vuchId.trim().length > 0) {
            t_This.initDataSaveFlag1 = false // 조회 데이터 있음
          } else {
            t_This.fn_InsEltrnSignPrgInfo(t_This.lv_HoshInfoList, t_This.lv_CommData.planInfo) // 조회 데이터 없음 - insert
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_InsEltrnSignPrgInfo
    * 설명       : 전자서명 진행 상태 데이터 저장 함수 - TTSD00001 Table
    ******************************************************************************/
    fn_InsEltrnSignPrgInfo (hoshInfoList, planInfo) {
      let t_This = this
      let pParams = elstPrgStatVO()
      pParams.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId // 영수증ID
      pParams.elstPlanId = this.lv_InputData.entplId // 설계ID
      pParams.clctCnsltNo = PSServiceManager.getUserInfo('userId') || ' ' // FC사번
      pParams.elstPrgStatCd = '00' // 전자서명진행상태코드 - 00:미서명, 10:서명중, 20:서명완료, 30:접수완료, 40:이체완료
      pParams.moblCswfYn = this.lv_InputData.isMoblPftSale ? 'Y' : 'N' // 모바일완전판매
      if (hoshInfoList) {
        let hoshInfoList_11 = hoshInfoList.filter(( item ) => { return item.contvInsrdCd === '11' })[0] // 계약자
        let hoshInfoList_21 = hoshInfoList.filter(( item ) => { return item.contvInsrdCd === '21' })[0] // 주피자
        pParams.contrInsrdSameYn = (hoshInfoList_11.agtFileId === hoshInfoList_21.agtFileId) ? 'Y' : 'N' // 계약자피보험자동일여부  동일:Y, 상이:N
        pParams.mnisdMiorYn = (this.$bizUtil.compareCurrentAge(hoshInfoList_21.custRrnId.substr(0, 6), this.standardAge)) ? 'N' : 'Y'// 주피미성년자여부 성년:N, 미성년:Y
        pParams.unchYn = (hoshInfoList_21.custRrnId === '0000003000000') ? 'Y' : 'N' // 태아여부 태아:Y, 아님:N
      }
      pParams.insrdTrtyFgr = this.lv_CommData.insrdTrtyFgr // 피보험자특약수
      pParams.aisdTrtyFgr = this.lv_CommData.aisdTrtyFgr // 종피보험자특약수
      pParams.secusFrmCd = this.lv_CommData.secusFrmCd // 증권양식값
      pParams.untyCovrInsrTypCd = this.lv_CommData.untyCovrInsrTypCd // 통합보장보험유형값
      pParams.ptclrPrdtTypCd = this.lv_CommData.ptclrPrdtTypCd // 특이상품유형값
      pParams.pdinsRCCId = this.lv_CommData.pdinsRCCId // 상품보험관계구성코드
      pParams.kliaInsrScCd = this.lv_CommData.kliaInsrScCd // 생보협회상품분류값

      pParams.ernam = PSServiceManager.getUserInfo('userId') || ' ' // 생성자id
      pParams.aenam = PSServiceManager.getUserInfo('userId') || ' ' // 변경자id

      // 191217 예금주 정보추가
      pParams.contrDpstrSameYn = this.lv_InputData.lv_dpstr.contrDpstrSameYn // 계약자예금주동일여부
      pParams.insrdDpstrSameYn = this.lv_InputData.lv_dpstr.insrdDpstrSameYn // 피보험자예금주동일여부
      pParams.dpstrIdpnYn = this.lv_InputData.lv_dpstr.dpstrIdpnYn // 예금주독립여부
      pParams.insrTypMngCd = this.lv_CommData.insrTypMgId // 보험유형관리코드RD (ASR210500282_2021.05.31 PJO)
      pParams.prdtTypMgCd = this.lv_CommData.prdtTypMgId // 상품유형관리코드RD (ASR220400794_22.05.10 PJO)
      pParams.prmPadTypValD = this.lv_CommData.prmPadTVId // 보험료납입유형값RD (ASR240400333_24.05 PJO/장한나)

      let trnstId = 'txTSSPS98I1'
      this.post(this, pParams, trnstId, 'I', {}, true)
        .then(function (response) {
          if (response.body && response.body.trtRslt && response.body.trtRslt > 0) {
            t_This.initDataSaveFlag1 = true // 저장완료
          } else {
            // 진행 상태 데이터 저장 에러
            t_This.getStore('confirm').dispatch('ADD', response.msgComm.msgCd + ' ' + response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_SelElstDtlInfo
    * 설명       : 전자서명 상세 데이터 조회 함수 - TTSD00002 Table
    ******************************************************************************/
    fn_SelElstDtlInfo (id) {
      let t_This = this
      let pParams = {vuchId: id}
      let trnstId = 'txTSSPS99S2'

      this.post(this, pParams, trnstId, 'S', {}, true)
        .then(function (response) {
          if (response.body && response.body.vuchId && response.body.vuchId.trim().length > 0) {
            t_This.initDataSaveFlag2 = false // 조회 데이터 있음
          } else {
            t_This.fn_InsElstEtlInfo(t_This.lv_HoshInfoList, t_This.lv_CommData.planInfo) // 조회 데이터 없음 - insert
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_InsElstEtlInfo
    * 설명       : 전자서명 설계 및 청약/접수 상세 정보 저장 함수 - TTSD00002 Table
    ******************************************************************************/
    async fn_InsElstEtlInfo (hoshInfoList, planInfo) {
      let t_This = this
      let pParams = befrInptInfoVO()
      pParams.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId // 영수증ID
      console.log('this.lv_InputData.zzaftRctmFg' + this.lv_InputData.zzaftRctmFg )
      pParams.aftRctmHopYn = (this.lv_InputData.zzaftRctmFg === '1') ? 'Y' : 'N' // 후입금희망여부
      // 보험거래문서디지털화 : 출금동의서 160번 발행목록은 청약서에 합본되어, 출금동의서가 구성된것으로 간주함.
      //                        출금동의서가 구성된경우 후입금이 아님
      pParams.aftRctmHopYn = 'N' // 후입금희망여부
      console.log('pParams.aftRctmHopYn' + pParams.aftRctmHopYn )
      pParams.elstPlanId = this.lv_InputData.entplId // 설계ID
      pParams.clctCnsltNo = PSServiceManager.getUserInfo('userId') || ' ' // FC사번
      pParams.isuMthScCd = this.lv_InputData.moDCDocuMngVO.fstIsuMthScCd || ' ' // 최초교부방법구분코드
      if (hoshInfoList) {
        let hoshInfoList_11 = hoshInfoList.filter(( item ) => { return item.contvInsrdCd === '11' })[0] // 계약자
        let hoshInfoList_21 = hoshInfoList.filter(( item ) => { return item.contvInsrdCd === '21' })[0] // 주피자
        pParams.elstPartnNo = hoshInfoList_11.partnerId // 비즈니스파트너번호
        pParams.elstInsrdPartnNo = hoshInfoList_21.partnerId // 전자서명피보험자파트너번호
        pParams.mobslChnlCustId = hoshInfoList_11.agtFileId // 계약자채널고객ID
        pParams.mobslChnlCstgrId = (hoshInfoList_11.detail.chnlCstgrId) ? hoshInfoList_11.detail.chnlCstgrId : ' ' // 채널고객그룹ID
        pParams.insrdChnlCustId = hoshInfoList_21.agtFileId // 피보험자채널고객ID
        pParams.mnContrNm = hoshInfoList_11.insrdNm // 계약자명
        pParams.insrdNm = (hoshInfoList_11.agtFileId !== hoshInfoList_21.agtFileId) ? hoshInfoList_21.insrdNm : ' ' // 피보험자명
      }
      pParams.prcd = this.lv_CommData.planInfo[0].prcdId || ' ' // 보험의 상품코드 - 조회해온 상품코드를 넣음(9자리)
      pParams.mnPrdtNm = this.lv_InputData.moDCDocuMngVO.prdtNm || ' ' // 상품명
      if (planInfo) {
        pParams.contPrm = planInfo[0].smtotPrm // 계약보험료
      }
      pParams.coprClctYn = this.lv_CommData.coprClctYn || ' ' // 공동모집여부

      pParams.ernam = PSServiceManager.getUserInfo('userId') || ' ' // 생성자id
      pParams.aenam = PSServiceManager.getUserInfo('userId') || ' ' // 변경자id

      // 191217 예금주 정보추가
      pParams.dpstrMobslChnlCustId = this.lv_InputData.lv_dpstr.chnlCustId // 예금주모바일영업채널고객ID
      pParams.dpstrNm = this.lv_InputData.lv_dpstr.custNm // 예금주명
      pParams.dpstrRltnCd = this.lv_InputData.lv_dpstr.contrFmrt // 계약자/예금주관계ID

      // 202005 외화보험 통화키
      pParams.crncyCd = this.lv_InputData.moDCDocuMngVO.currencyId // 통화코드

      // 201222 지정대리청구인 정보
      pParams.apcAsgnYn = this.lv_InputData.lv_apcInfo.apcAsgnYn // 지정대리청구지정여부
      pParams.apcChnlCustId = this.lv_InputData.lv_apcInfo.apcChnlCustId // 지정대리청구 모바일영업채널고객ID
      pParams.apcCustNm = this.lv_InputData.lv_apcInfo.apcCustNm // 지정대리청구명
      pParams.contrRltnCd = this.lv_InputData.lv_apcInfo.contrRltnCd // 계약자관계코드
      // 지정대리청구인_2인가능_수정_START(ASR210500282_20210531_PJO)
      pParams.apcPrsonAplScCd = this.lv_InputData.lv_apcInfo.apcPrsonAplScCd // 지정대리청구인신청구분코드2
      pParams.apcChnlCustId02 = this.lv_InputData.lv_apcInfo.apcChnlCustId02 // 지정대리청구 모바일영업채널고객ID2
      pParams.apcCustNm02 = this.lv_InputData.lv_apcInfo.apcCustNm02 // 지정대리청구명2
      pParams.contrRltnCd02 = this.lv_InputData.lv_apcInfo.contrRltnCd02 // 계약자관계코드2
      // ASR240200999_간편지대청 도입(김문준/240423)_START
      pParams.simpAsgnClamAgntCd01 = this.lv_InputData.lv_apcInfo.simpAsgnClamAgntCd01 // 지정대리인1_입력유형
      pParams.simpAsgnClamAgntCd02 = this.lv_InputData.lv_apcInfo.simpAsgnClamAgntCd02 // 지정대리인2_입력유형
      // ASR240200999_간편지대청 도입(김문준/240423)_END

      // 피보험자관계코드, 친권1,2 정보추가_(ASR210600884_20210720_PJO)
      pParams.prtrNm01 = this.lv_InputData.prtrNm01 // 친권자명01
      pParams.prtrChnlCustId01 = this.lv_InputData.prtrChnlCustId01 // 친권자채널고객ID01
      pParams.prtrInsrdRltnScCd01 = this.lv_InputData.prtrInsrdRltnScCd01 // 친권자피보험자와의관계구분코드01
      pParams.prtrNm02 = this.lv_InputData.prtrNm02 // 친권자명02
      pParams.prtrChnlCustId02 = this.lv_InputData.prtrChnlCustId02 // 친권자채널고객ID02
      pParams.prtrInsrdRltnScCd02 = this.lv_InputData.prtrInsrdRltnScCd02 // 친권자피보험자와의관계구분코드02
      pParams.insrdContrRltnScCd = this.lv_InputData.insrdContrRltnScCd // 피보험자_계약자와의관계구분코드

      // 친권자 휴대폰번호 추가 ASR211200564_친권인 연락처 사전정보입력(2022.02.08/PJO)
      pParams.prtrTelnoEncr01 = this.lv_InputData.prtrTelnoEncr01 // 친권1휴대폰번호
      pParams.prtrTelnoEncr02 = this.lv_InputData.prtrTelnoEncr02 // 친권2휴대폰번호

      //AML 대응 처리 추가된 컬럼 값 셋팅 전자서명 완료시 부터 AML 처리 기준값 
      let isAmlCntr      = PSAmlUtil.fn_IsAmlPrfmc('1',this.lv_InputData.jsonInputData) // 계약자 AML 대상여부
      let isAmlPrtr01    = PSAmlUtil.fn_IsAmlPrfmc('2',this.lv_InputData.jsonInputData) // 친권자01 AML 대상여부
      let isAmlDthBnfr01 = PSAmlUtil.fn_IsAmlPrfmc('3',this.lv_InputData.jsonInputData) // 사망수익자01 AML 대상여부

      if(isAmlCntr) pParams.amlContrPartnNo	 = pParams.elstPartnNo // 계약자 BPID 셋팅
      if(isAmlPrtr01) pParams.amlPrtrPesonPartnNo = pParams.elstPartnNo // 정보확인단계에서 지정 선택된 친권자 BPID 셋팅 전자서명은 친권자 = 계약자 동일
      if(isAmlDthBnfr01) {
        //전산심사결과 조회 에서 수익자정보 셋팅함 this.lv_CommData.userData3 = event.bnfrInfoList  
        let dthBnfr01Info = await PSAmlUtil.fn_GetBnfrInfo(this,this.lv_CommData.userData3)
        if(dthBnfr01Info){
          pParams.amlDthBnfrPartnNo	   = dthBnfr01Info.partnerId	//AML사망수익자파트너번호
          pParams.amlDthBnfrChnlCustId = dthBnfr01Info.agtFileId	//AML사망수익자채널고객ID
          pParams.amlDthBnfrCustNm	   = dthBnfr01Info.custNm	//AML사망수익자고객명
          // ------------------------------------------------- (AML 보완 / 2311_PJO)_START
          // 사망수익자정보 (lv_CommData.userData3DthBnfr01AML) 세팅: BPID,채널고객ID,고객명,고객카드 ( 주소정보 선택시 사용하기 위함 )
          let amlRsltCustInfo = null
          amlRsltCustInfo = await PSAmlUtil.fn_GetAmlChnlCustInfo('MSPPS420M',dthBnfr01Info.agtFileId,dthBnfr01Info.custNm)
          this.lv_CommData.userData3DthBnfr01AML = {}
          if(amlRsltCustInfo.isError === false){
            console.log('고객카드조회성공')
            this.lv_CommData.userData3DthBnfr01AML.detail = amlRsltCustInfo.data // 사망수익자1의 고객카드 정보 조회(txTSSPS13S4/F1MDI0270)
            this.lv_CommData.userData3DthBnfr01AML.partnerId = dthBnfr01Info.partnerId
            this.lv_CommData.userData3DthBnfr01AML.agtFileId = dthBnfr01Info.agtFileId
            this.lv_CommData.userData3DthBnfr01AML.custNm = dthBnfr01Info.custNm
            this.lv_CommData.isAmlDthBnfr01 = true // MSPPS422D에서 사용하기위함
          } else{
            //에러시 에러 로그 기록
            console.log('MSPPS420M.사망수익자의 고객카드 정보 조회시오류===>\n' + dthBnfr01Info.partnerId + '/' + dthBnfr01Info.partnerId + '/' + dthBnfr01Info.agtFileId  + '/' + dthBnfr01Info.custNm )
          }
          // ------------------------------------------------- (AML 보완 / 2311_PJO)_END
        }
      }

      // console.log('MSPPS420M===>\n' + JSON.stringify(pParams) )
      let trnstId = 'txTSSPS99I5' //AML대응 'txTSSPS99I1'      
      this.post(this, pParams, trnstId, 'I', {}, true)
        .then(function (response) {
          if (response.body && response.body.trtRslt && response.body.trtRslt > 0) {
            t_This.initDataSaveFlag2 = true // 저장완료
          } else {
            t_This.getStore('confirm').dispatch('ADD', response.msgComm.msgCd + ' ' + response.msgComm.msgDesc)
            // 진행 상태 데이터 저장 에러
          }

          //전자서명 사용자 이벤트 로그 기록 처리
          let logVuchId = t_This.lv_InputData.moDCDocuMngVO.eltrnDoctId
          PSCommUtil.prcsEvtLog(t_This,'MSPPS420M','사전입력',logVuchId)
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명 : fn_MoveEvent
    * 설명       : 현재 보여지는 화면의 하단 이전/다음 버튼 이벤트 처리 함수
    ******************************************************************************/
    fn_MoveEvent (target, dataObj) {
      if (dataObj) {
        // dataObj : 본인인증완료 : TSSPS121D
        this.lv_CommData.contractorCertify = dataObj.contractorCertify // 계약자 인증 여부
        this.lv_CommData.dpstrCertify = dataObj.dpstrCertify // 예금주 인증 여부
        let hoshInfoList_21 = this.lv_HoshInfoList.filter(( item ) => { return item.contvInsrdCd === '21' })[0] // 주피자
        if (this.isInsrdMinor) { // 계피상이, 피보험자가 미성년자일때 - 피보험자 데이터 넣어주고 인증여부를 true로 셋팅해준다. DB에도 저장한다.
          if (hoshInfoList_21.custRrnId === '0000003000000') { // 태아일때는 피보험자 데이터 넣지 않음
            this.lv_CommData.userData2 = {}
            this.lv_CommData.insuredCertify = false
            this.lv_CommData.unchYn = 'Y'
          } else {
            this.lv_CommData.userData2 = hoshInfoList_21
            this.lv_CommData.userData2.knb = this.lv_InputData.moDCDocuMngVO.moblBusnScCd // 업무구분(SE:청약)
            this.lv_CommData.insuredCertify = true
          }
          let pParams = {
            aenam: PSServiceManager.getUserInfo('userId') || ' ', // 변경자id
            insrdAthntMethCd: 'P', // 피보험자가 미성년일때 DB에 인증값 Pass 넣어준다
            vuchId: this.lv_InputData.moDCDocuMngVO.eltrnDoctId
          }
          let trnstId = 'txTSSPS98U10'
          this.post(this, pParams, trnstId, 'U').then(function (response) {}).catch(function (error) { window.vue.error(error) })
        } else {
          this.lv_CommData.insuredCertify = dataObj.insuredCertify // 피보험자 인증 여부
        }
        // 전자서명여부 false로 설정
        this.lv_CommData.contrElstFlag = false // 계약자 전자서명Flag
        this.lv_CommData.insrdElstFlag = false // 피보험자 전자서명Flag
        this.lv_CommData.dpstrElstFlag = false // 예금주 전자서명Flag

        this.lv_CommData.contrJobFlag = false // 계약자 직업명(관계자정보확인 입력 여부 판단)
        this.lv_CommData.insrdJobFlag = false // 피보험자 직업명(관계자정보확인 입력 여부 판단)
        this.lv_CommData.dpstrBankFlag = false // 예금주 계좌정보(예금정보 입력여부 확인)

        // 210M 에서 넘어왔을때는 계약관계자정보가 모두 입력된 상태임으로 바로 전자서명앱으로 연결해준다
        if (this.inputPath === '210M') {
          // PSElstUtil.js : S4: 전자서명진행상태조회 => lv_CommData.eltrnSaveData
          this.lv_CommData.contrElstFlag = this.lv_CommData.eltrnSaveData.contrElstYn.trim() === 'Y' // 계약자 전자서명Flag
          this.lv_CommData.insrdElstFlag = this.lv_CommData.eltrnSaveData.insrdElstYn.trim() === 'Y' // 피보험자 전자서명Flag
          this.lv_CommData.dpstrElstFlag = this.lv_CommData.eltrnSaveData.dpstrElstYn.trim() === 'Y' // 예금주 전자서명Flag

          this.lv_CommData.contrJobFlag = this.lv_CommData.eltrnSaveData.contrJobpNm.trim() !== '' // 계약자 직장명(관계자정보확인 입력 여부 판단)
          this.lv_CommData.insrdJobFlag = this.lv_CommData.eltrnSaveData.insrdJobpNm.trim() !== '' // 피보험자 직장명(관계자정보확인 입력 여부 판단)
          this.lv_CommData.dpstrBankFlag = this.lv_CommData.eltrnSaveData.frtmPrmPadMthCd.trim() !== '' // 초회보험료납입방법코드(예금정보 입력여부 확인)
        } // if (this.inputPath === '210M')

        if (!this.$bizUtil.isEmpty(dataObj.frtmPrmPadMthCd)) { // MSPPS423D화면에서 납입방법 선택 코드를 MSPPS424D에전달하기 위해 사용함.
          this.lv_CommData.frtmPrmPadMthCd = dataObj.frtmPrmPadMthCd
        }
      } // if (dataObj)

      /**
       * 선심사 정보 조회 (선심사PJT 추가 - 2024.02.02)
       * 선심사 부적합 정보 사유 정보 서식에 추가
       */
      if (target == 'nundw') {
        this.lv_InputData.nundwNrmYn = this.lv_CommData.nundwNrmYn = dataObj.nundwNrmYn
        this.lv_InputData.nundwOpin = this.lv_CommData.nundwOpin = dataObj.nundwOpin

        // 부적합사유 서식정보에 추가
        if (dataObj.nundwNrmYn === 'N') {
          /* -- 종피 고지서는 제외됨(2024.03.11-선심사PJT)
          let hoshInfoList_21 = this.lv_HoshInfoList.filter(( item ) => { return item.contvInsrdCd === '21' })[0] // 주피자
          let bInsrMinor = false // 종피정보가 있는지 여부
          if  (this.isInsrdMinor && hoshInfoList_21.custRrnId === '0000003000000') { // 계피상이면서 주피가 태아인경우, 종피 고지서 정보로 세팅함.
            bInsrMinor = true
          }
          */

          let inputObj = this.lv_CommData.nundwPvmnotice
          let strCnt = '' + (this.numNundwOpin + 1)
          if (this.numNundwOpin < 10) {
            strCnt = '0' + (this.numNundwOpin + 1)
          }

          let tagNm = "OZ3_D" + strCnt + "_"
          /* -- 종피 고지서는 제외됨(2024.03.11-선심사PJT)
          if (bInsrMinor) {
            tagNm = 'OZ4_D5' + strCnt + '_'
          }
          */

          let keyNm = tagNm + 'NUNDW' // ex) OZ3_D03_NUNDW or OZ4_D503_NUNDW // 계피동일/주피 or 종피인경우
          inputObj[keyNm] = dataObj.nundwOpin

          keyNm = tagNm + 'ID' // ex) ID부여
          inputObj[keyNm] = 'D' + strCnt

          keyNm = tagNm + 'CD_NM' // ex) Title넣어줌
          inputObj[keyNm] = '고객추가고지'

          keyNm = tagNm + 'CD' // ex) Title넣어줌
          inputObj[keyNm] = '고객추가고지'

          keyNm = tagNm + 'Title' // ex) Title넣어줌
          inputObj[keyNm] = '고객추가고지'
        }
      }

      // 다음페이지로 이동
      if (target === 'previous') {
        this.currentStep--
      } else if (target === 'next') {
        if (this.currentStep === 3) { // 전자서명 3번째 탭에서 하단 '다음' 버튼 선택 시 서명앱 연결
          if (this.nundwYn == 'Y') { // 선심사 정보가 있는 경우 선심사 정보 확인 화면으로 이동
            this.currentStep = 4
          } else {
            this.fn_MoveApp()
          }
        } else if (this.currentStep === 2) {
          if (this.lv_CommData.dpstrCertify && !this.lv_CommData.dpstrBankFlag ) {
            // 예금주_인증O, 예금주_계좌정보X
            this.currentStep = 3
          } else {
            this.fn_MoveApp()
          }
        } else if (this.currentStep === 1) {
          // 전자서명 1번 탭에서 [인증완료] 선택
          if (this.insuredPerson) { // 계피상이
            if (this.lv_CommData.contractorCertify && !this.lv_CommData.contrJobFlag && this.lv_CommData.insuredCertify && !this.lv_CommData.insrdJobFlag) {
              // 계약자_인증O, 계약자_직업명X, 피보험자_인증O, 피보험자_직업명X
              this.currentStep = 2
            } else if (this.lv_CommData.contractorCertify && !this.lv_CommData.contrJobFlag && this.lv_CommData.insuredCertify && this.lv_CommData.insrdJobFlag) {
              // 계약자_인증O, 계약자_직업명X, 피보험자_인증O, 피보험자_직업명O
              this.lv_CommData.insuredCertify = false // 피보험자_인증 변경 : true -> false
              this.currentStep = 2
            } else if (this.lv_CommData.contractorCertify && this.lv_CommData.contrJobFlag && this.lv_CommData.insuredCertify && !this.lv_CommData.insrdJobFlag) {
              // 계약자_인증O, 계약자_직업명O, 피보험자_인증O, 피보험자_직업명X
              this.lv_CommData.contractorCertify = false // 계약자_인증 변경 : true -> false
              this.currentStep = 2
            } else if (this.lv_CommData.contractorCertify && !this.lv_CommData.contrJobFlag && !this.lv_CommData.insuredCertify) {
              // 계약자_인증O, 계약자_직업명X, 피보험자_인증X
              this.currentStep = 2
            } else if (!this.lv_CommData.contractorCertify && this.lv_CommData.insuredCertify && !this.lv_CommData.insrdJobFlag) {
              // 계약자_인증X, 피보험자_인증O, 피보험자_직업명X
              this.currentStep = 2
            } else if (this.lv_CommData.dpstrCertify && !this.lv_CommData.dpstrBankFlag) {
              // 예금주_인증O, 예금주_계좌정보X
              this.currentStep = 3
            } else if (this.lv_CommData.contractorCertify && this.lv_CommData.contrJobFlag && this.lv_CommData.insuredCertify && this.lv_CommData.insrdJobFlag && this.lv_CommData.dpstrCertify && this.lv_CommData.dpstrBankFlag ) {
              // 계약자_인증O, 계약자_직업명O, 피보험자_인증O, 피보험자_직업명O, 예금주_인증O, 예금주_계좌정보O
              this.fn_MoveApp()
            } else if (this.lv_CommData.contractorCertify && this.lv_CommData.contrJobFlag && this.lv_CommData.dpstrCertify ) {
              // 계약자_인증O, 계약자_직업명O, 예금주_인증O
              this.fn_MoveApp()
            } else if (this.lv_CommData.insuredCertify && this.lv_CommData.insrdJobFlag && this.lv_CommData.dpstrCertify ) {
              // 피보험자_인증O, 피보험자_직업명O, 예금주_인증O
              this.fn_MoveApp()
            } else if (!this.lv_CommData.contractorCertify && this.lv_CommData.insuredCertify && this.lv_CommData.insrdJobFlag) {
              // 계약자_인증X, 피보험자_인증O, 피보험자_직업명O
              // 2020.03.17 추가
              this.fn_MoveApp()
            } else {
              this.getStore('confirm').dispatch('ADD', '전자서명 페이지로 이동합니다.(110-2)')
              this.fn_MoveApp()
            }
          } else {
            // 계피동일
            if (this.lv_CommData.contractorCertify && !this.lv_CommData.contrJobFlag) {
              // 계약자_인증O, 계약자_직업명X
              this.currentStep = 2
            } else if (this.lv_CommData.dpstrCertify && !this.lv_CommData.dpstrBankFlag ) {
              // 예금주_인증O, 예금주_계좌정보X
              this.currentStep = 3
            } else if (this.lv_CommData.contractorCertify && this.lv_CommData.contrJobFlag && this.lv_CommData.dpstrCertify && this.lv_CommData.dpstrBankFlag ) {
              // 계약자_인증O, 계약자_직업명O, 예금주_인증O, 예금주_계좌정보O
              this.fn_MoveApp()
            } else if (this.lv_CommData.contractorCertify && this.lv_CommData.contrJobFlag && !this.lv_CommData.dpstrCertify ) {
              // 계약자_인증O, 계약자_직업명O, 예금주_인증X
              this.fn_MoveApp()
            } else {
              this.getStore('confirm').dispatch('ADD', '전자서명 페이지로 이동합니다.(110-1)')
              this.fn_MoveApp()
            }
          } // 계피상이 else
        } // else if (this.currentStep === 1)
      } else if (target === 'app') { // 전자서명 2번째 탭에서 하단 다음 선택 시 '후입금' Y 이면 서명앱으로 연결
        this.fn_MoveApp()
      } else if (target == 'nundw') { // 선심사 정보 조회 후에는 서명앱으로 연결
        this.fn_MoveApp()
      }
    },

    /******************************************************************************
    * Function명 : fn_MoveApp
    * 설명       : 전자서명 앱으로 이동 함수
    ******************************************************************************/
    fn_MoveApp () {
      if (this.inputPath === '210M') { // 설계청약 현황 페이지에서 420M으로 넘어와서 610M으로 넘길때 reqNameID: 'MSPPS420M_1' 으로 셋팅
        this.$MenuManager.fn_RouterPushSrnId('MSPPS610M', {reqNameID: 'MSPPS420M_1', itemData: this.lv_InputData, step: '1'})
      } else {
        this.$MenuManager.fn_RouterPushSrnId('MSPPS610M', {reqNameID: 'MSPPS420M', itemData: this.lv_InputData, step: '1'})
      }
    },

    /************************************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ************************************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },

    /******************************************************************************
    * Function명 : fn_HeaderBackBtnHandler
    * 설명       : 이전화면 이동
    ******************************************************************************/
    fn_HeaderBackBtnHandler () {
      if (this.inputPath === '210M') {
        this.fn_ConfirmExit('전자서명 진행을 종료하고 전자서명현황으로 이동하시겠습니까?', '')
      }
      else {
        this.fn_ConfirmExit('전자서명 진행을 종료하고 청약서발행현황으로 이동하시겠습니까?', 'MSPPS220M')
      }
    },

    fn_ConfirmExit(content, srnId) {
      const lv_ShowConfirmExit = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: '알림',
          content: content,
          title_pos_btn: "예",
          title_neg_btn: "아니오"
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(lv_ShowConfirmExit)
            if (this.inputPath === '210M') {
              this.$router.go(-1);
            }
            else {
              // 청약서발행으로 이동
              this.$MenuManager.fn_RouterPushSrnId(srnId)
            }
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(lv_ShowConfirmExit)
          },
          onPopupClose: () => {
            this.$bottomModal.close(lv_ShowConfirmExit)
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_NundwData
    * 설명       : 선심사 정보 조회 (선심사PJT 추가 - 2024.02.02)
    ******************************************************************************/
    fn_NundwData () {
      let pParams = {}
      pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS99S15'))
      pParams.srnId = this.$options.screenId // 호출 화면 명
      pParams.data.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId
      pParams.data.elstPlanId = this.pId
      PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, 'S', this.fn_ServiceFaultHandler)
    },
    fn_ServiceResultHandler: function (event) {      
      /**
     * 선심사 정보 조회 (선심사PJT 추가 - 2024.02.05)
     * 1. F1PQL0483(선심사신청정보 조회)
     * 2. 선심사 사전정보 및 질병상세 조회 I/F 호출     
     * 4. pvmnotice 정보 세팅, 선심사여부 nundwYn 세팅  및 lv_InputData.nundwYn, lv_InputData.nundwPvmnotice에 추가함. 혹은 lv_CommData.nundwYn, lv_CommData.nundwPvmnotice세팅

     * 1. 선심사적합성여부 및 미적합사유 정보 조회
     * 2. DB에서 조회된 선심사적합성여부 및 미적합사유 정보 세팅: this.lv_InputData.nundwNrmYn, this.lv_InputData.nundwOpin
     * 
     * 신규함수 호출할 것. (fn_NundwData)
     * */

      let lv_data = event.data

      this.nundwYn = this.lv_InputData.nundwYn = this.lv_CommData.nundwYn = lv_data.nundwYn // 선심사사용여부

      if (this.nundwYn == 'Y') {
        if (!this.$bizUtil.isEmpty(lv_data.nundwInfoTrtDto)) {
          this.lv_InputData.nundwInfoTrtDto = this.lv_CommData.nundwInfoTrtDto = lv_data.nundwInfoTrtDto
        }
        this.lv_InputData.nundwNrmYn = lv_data.nundwNrmYn // 선심사고지정상 여부
        this.lv_InputData.nundwOpin = lv_data.nundwOpin // 추가고지입력
        
        if (lv_data.nundwInfoTrtDto != null && lv_data.nundwInfoTrtDto.befrInfoDto != null) {
          /* -- 종피 고지서는 제외됨(2024.03.11-선심사PJT)
          let hoshInfoList_21 = this.lv_HoshInfoList.filter(( item ) => { return item.contvInsrdCd === '21' })[0] // 주피자
          let bInsrMinor = false // 종피정보가 있는지 여부
          if  (this.isInsrdMinor && hoshInfoList_21.custRrnId === '0000003000000') { // 계피상이면서 주피가 태아인경우, 종피 고지서 정보로 세팅함.
            bInsrMinor = true
          }
          */

          let gojiInfo = JSON.parse(lv_data.nundwInfoTrtDto.befrInfoDto.dsclsInptInfoCntnt)
          let inputObj = gojiInfo.pvmnotice.formData[0].Input

          /* -- 종피 고지서는 제외됨(2024.03.11-선심사PJT) // 사전정보 입력 정보 세팅 변경 (종피는 오즈서식에서는 OZ2_G501_YN과 같은방식으로 key가 세팅됨. 선심사 정보에서는 무조건 OZ1_G01~~과 같은 방식으로 값이 넘어옴.)
          if (bInsrMinor) { // 종피인경우
            let lvInputObj = {}
            Object.keys(inputObj).forEach((key) => {
              let keyStr = key
              if (key.indexOf('G9') < 0) { // G9번대로 시작하는건 OZ1_, OZ2_ 모두 공통으로 사용함 따라서 예외로 처리해야함.
                keyStr = key.replace('OZ1_G', 'OZ2_G5')
              } else {
                keyStr = key.replace('OZ1', 'OZ2')
              }
              let valStr = inputObj[key]
              if (valStr.startsWith('G')) {
                valStr = valStr.replace('G', 'G5')
              }
              lvInputObj[keyStr] = valStr
            })

            gojiInfo.pvmnotice.formData[0].Input = inputObj = lvInputObj
          }
          */

          if (!this.$bizUtil.isEmpty(lv_data.nundwInfoTrtDto.diseDtlDto) && 
            lv_data.nundwInfoTrtDto.diseDtlDto.length > 0) {
              let list = lv_data.nundwInfoTrtDto.diseDtlDto
              for (let i = 0; i < list.length; i++) {
                let subDetailDto = list[i]
                if (subDetailDto.krReprDiseClsfCd.indexOf('*') < 0) { // masking 표시가 없는 항목만 추출함 - 동의를 하지 않는 경우 *로 표시됨.
                  let strCnt = '' + (this.numNundwOpin + 1)
                  if (this.numNundwOpin < 10) {
                    strCnt = '0' + (this.numNundwOpin + 1)
                  }

                  this.numNundwOpin++ // 선심사 이상유무 확인 후, 사유를 입력해야 하는 리스트 순번(맨마지막에 넣기 위해 계속 현재순번을 count함.)

                  let tagNm = "OZ3_D" + strCnt + "_"
                  /* -- 종피 고지서는 제외됨(2024.03.11-선심사PJT)
                  if (bInsrMinor) {
                    tagNm = 'OZ4_D5' + strCnt + '_'
                  }
                  */

                  let keyNm = tagNm + "CD"
                  inputObj[keyNm] = subDetailDto.krReprDiseClsfCd // 질병코드

                  keyNm = tagNm + "CD_NM"
                  inputObj[keyNm] = subDetailDto.krReprDiseClsfCdNm // 질병명

                  keyNm = tagNm + 'Title'
                  inputObj[keyNm] = subDetailDto.krReprDiseClsfCdNm // 질병명

                  keyNm = tagNm + "ID" // OZ3_D01_ID
                  /* -- 종피 고지서는 제외됨(2024.03.11-선심사PJT)
                  if (bInsrMinor) { // 종피고지서인경우
                    inputObj[keyNm] = 'D5' + strCnt // ID
                  } else {
                    inputObj[keyNm] = 'D' + strCnt // ID
                  }
                  */
                  inputObj[keyNm] = 'D' + strCnt // ID

                  let subList = subDetailDto.diseQuestAnswDto
                  let etcSmry = ''
                  let mthstr = ''

                  if (!this.$bizUtil.isEmpty(subDetailDto.diseBodyRgnAnswNm)) { // 질병부위 선택한 경우
                    let answStr = subDetailDto.diseBodyRgnAnswNm.trim().replace(/\|/g, ',') + '번 부위,'
                    
                    etcSmry += answStr
                  }

                  for (let j = 0; j < subList.length; j++) {
                    let qaDto = subList[j]
                    if (qaDto.diseQuestScCd === '02') { // 02: 공통질문, 03: 추가질문
                      if (!this.$bizUtil.isEmpty(qaDto.questAnswCntnt)) {
                        if (qaDto.diseQuestCd === 'Q00915') { // 치료방법 및 내용
                          if (qaDto.questAnswCntnt === 'A91502') { // "A91502": 수술, "A91503": 입원, A91504:  통원, A91505: 치료안함,
                            mthstr += '11,'
                          }
                          if (qaDto.questAnswCntnt === 'A91503') { // "A91502": 수술, "A91503": 입원, A91504:  통원, A91505: 치료안함,
                            mthstr += '12,'
                          }
                          if (qaDto.questAnswCntnt === 'A91504') { // "A91502": 수술, "A91503": 입원, A91504:  통원, A91505: 치료안함,
                            mthstr += '13,'
                          }
                          if (qaDto.questAnswCntnt === 'A91505') { // "A91502": 수술, "A91503": 입원, A91504:  통원, A91505: 치료안함,
                            etcSmry += '치료안함,'
                            mthstr += '90,'
                          }
                        } else if (qaDto.diseQuestCd === 'Q00913') { // 전체치료기간/총치료일
                          if (qaDto.diseQuestAnswCd === 'A91301') { // "A91301" : 시작일, A91302 :  종료일, A91303 : 총치료일,
                            let yy = qaDto.questAnswCntnt.substring(0, 4) // YYYY
                            keyNm = tagNm + 'FR_YY'
                            inputObj[keyNm] = yy

                            let mm = qaDto.questAnswCntnt.substring(4, 6) // MM
                            keyNm = tagNm + 'FR_MM'
                            inputObj[keyNm] = mm
                          } else if (qaDto.diseQuestAnswCd === 'A91302') { // "A91301" : 시작일, A91302 :  종료일, A91303 : 총치료일
                            let yy = qaDto.questAnswCntnt.substring(0, 4) // YYYY
                            keyNm = tagNm + 'TO_YY'
                            inputObj[keyNm] = yy

                            let mm = qaDto.questAnswCntnt.substring(4, 6) // MM
                            keyNm = tagNm + 'TO_MM'
                            inputObj[keyNm] = mm
                          } else if (qaDto.diseQuestAnswCd === 'A91303') { // "A91301" : 시작일, A91302 :  종료일, A91303 : 총치료일
                            let dds = qaDto.questAnswCntnt
                            keyNm = tagNm + 'DDS'
                            inputObj[keyNm] = dds
                          }
                        } else if (qaDto.diseQuestCd === 'Q00911') { // 완치여부
                          if (qaDto.questAnswCntnt === 'A91101') { // 예
                            keyNm = tagNm + 'PFCUR_YN'
                            inputObj[keyNm] = 'Y'
                          } else if (qaDto.questAnswCntnt === 'A91102') { // 아니오
                            keyNm = tagNm + 'PFCUR_YN'
                            inputObj[keyNm] = 'N'
                          }
                        } else if (qaDto.diseQuestCd === 'Q00912') { // 재발유무(완치 판정이후)
                          if (qaDto.questAnswCntnt === 'A91201') { // 있음
                            keyNm = tagNm + 'RECUR_YN'
                            inputObj[keyNm] = 'Y'
                          } else if (qaDto.questAnswCntnt === 'A91202') { // 없음
                            keyNm = tagNm + 'RECUR_YN'
                            inputObj[keyNm] = 'N'
                          }
                        }
                      }
                    } else if (qaDto.diseQuestScCd === '03') {
                      if (!this.$bizUtil.isEmpty(qaDto.questAnswCntnt)) {
                        let subSmry = qaDto.questSmry + '&' + qaDto.answSmry + ','
                        etcSmry += subSmry
                      }
                    }
                  }

                  if (!this.$bizUtil.isEmpty(etcSmry)) { // 선심사에서 선택한 치료안함 및 추가질문&답변 정보
                    etcSmry = etcSmry.substring(0, (etcSmry.length - 1)) // 마지막 글자의 ','는 제거함.
                    if (mthstr.indexOf('90') < 0) { // 기타체크 값이 없는 경우 추가함.
                      mthstr += '90,'
                    }

                    keyNm = tagNm + 'NUNDW' // ex) OZ3_D01_NUNDW
                    inputObj[keyNm] = etcSmry
                  }

                  if (!this.$bizUtil.isEmpty(mthstr)) {
                    mthstr = mthstr.substring(0, (mthstr.length - 1)) // 마지막 글자의 ','는 제거함.
                    keyNm = tagNm + 'MTH'
                    inputObj[keyNm] = mthstr
                  }
                }
              } // end for (let i = 0; i < list.length; i++)
          }

          this.lv_InputData.nundwPvmnotice = this.lv_CommData.nundwPvmnotice = inputObj
        }
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      this.getStore('confirm').dispatch('ADD', event.msgDesc)
    },
    _fn_End(){return}//코딩 종료 함수
 },// methods

}//export default


</script>