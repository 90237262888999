/*
 * 업무구분: 개인가입설계
 * 화 면 명: MSPPI802D
 * 화면설명: 선심사 > 지급정보동의 > 사전정보 입력
 * 작 성 일: 2024.01.08
 */
<template>
  <ur-box-container direction="column" alignV="start" class="msp-pi-wrap">
    <!-- Content영역 -->
    <ur-box-container alignV="start" componentid="" direction="column" class="reserch-area">
      <ur-box-container alignV="center" direction="row" class="con-area reserch-step-head">
        <!-- 미결보완 시 START-->
        <div class="fexTy3 full pb20" v-if="lv_nundwJdgPrgStatCd === '50'">
          <div class="fexTy5 crTy-orange5"> 
            <mo-icon class="mr5">warning</mo-icon><b class="txtSkip fwm fs16rem">인심사 결과 보완이 필요합니다.</b>
          </div> 
          <div class="">
            <mo-button componentid="" color="blue" shape="hyperlink" size="large" class="crTy-blue4 minWauto hauto pal0 fs16rem" @click="$emit('openMSPPI830P')">심사의견조회</mo-button> <!-- MSPPI830P 이동 -->
          </div>
        </div>
        <!-- // 미결보완 시 END-->  
        <mo-checkbox v-model="allChkNo" small class="crTy-bk7" v-show="!isReadOnly" @input="fn_SetAllNo">모두 아니오</mo-checkbox>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column">
        <div class="reserch-list type2 pt0" >
          <mo-list :list-data="QUESTION_LIST" >
            <template #list-item="{item}">
                <!-- 입력 타입(CHOICETYPE) 1, 2, 3-->
              <div v-if="item.CHOICETYPE === '1' || item.CHOICETYPE === '2' || item.CHOICETYPE === '3'">
                <mo-list-item>
                  <div class="list-item__contents">
                    <msp-expand btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="fexTy3-1 firstdiv-full"
                      :ref="`refExpand${item.ID}`" @expanded="onExpanded($event, item)">
                      <template #title class="w100">
                        <div class="q-title">
                          <b class="q-title-num">{{item.INDEX}}.</b> <span v-html="item.QUESTION"></span>
                        </div>
                      </template>
                      <template #btn>
                        <mo-button class="link-arrow down"></mo-button>
                      </template>
                      <template #content>
                        <div class="q-content">
                          <!-- SUB_QUESTION START-->
                          <div class="text-gray-box mt20" v-if="item.SUB_QUESTION || item.DEFINITION !== ''">
                            <ul class="terms-list-area crTy-bk7 fs14rem" v-if="item.DEFINITION !== ''">
                              <li v-for="def in item.DEFINITION" :key="def.title" class="crTy-bk7">{{def.title}}{{def.content}}</li>
                            </ul>
                          </div> 
                          <!-- SUB_QUESTION END-->
                          <!-- 예/ 아니오 BUTTON START -->
                          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt20" v-if="item.CHOICETYPE !== '3'">
                            <mo-segment-wrapper solid primary class="chip-type-wrap chip-ty1" @input="fn_setAnswerYesNoBef(item)" v-model="item.ANSWER_YN" :disabled="item.DISABLED || isReadOnly" :ref="`focusOn${item.ID}`">
                              <mo-segment-button value="Y">예</mo-segment-button>
                              <mo-segment-button value="N">아니오</mo-segment-button>
                            </mo-segment-wrapper>
                          </ur-box-container>
                          <!-- 예/ 아니오 BUTTON END -->
                          <!-- 유효성 ERROR 메세지 START -->
                          <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0"  >
                            <div class="ns-certify-sed error" v-show="item.isErrorMsg1">
                              <div class="ns-certify-sed-txt">
                                <div class="txt-error">필수 입력 값입니다.</div>
                              </div>
                            </div>
                          </ur-box-container> 
                          <!-- 체크박스/라디오 선택 START -->
                          <ur-box-container alignV="start" componentid="" direction="column" class="ns-certify-wrap mt20" v-show="item.DETAILS || item.CHOICETYPE === '3'">
                            <div class="q-title must md10 fwm" v-if="item.CHOICETYPE !== '3'">해당하는 모든 항목을 체크해주세요.</div>
                            <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0 mt5" v-show="item.isErrorMsg2">
                              <div class="ns-certify-sed error">
                                <div class="ns-certify-sed-txt">
                                  <div class="txt-error">{{(item.ANSWERTYPE === 'S')? '필수입니다. 선택해주세요.': '필수입니다. 하나 이상 선택해주세요.'}}</div>
                                </div>
                              </div>
                            </ur-box-container>
                            <ur-box-container alignV="start" componentid="" direction="column" class="msp-req-lst" v-if="item.ANSWERTYPE !== 'S'">
                              <div v-for="d in item.DETAILS" :key="d.key" :class="(item.CHOICETYPE === '3' && d.key === 'ETC3') ? 'q-multi-item align-item-start mt20' : 'q-item'" >
                                <mo-checkbox :class="(d.key === 'ETC3') ? 'q-multi-chk ml0' :'mt10'" :ref="`focusOnChk${item.ID}`" v-model="item.DETAIL" :value="d.key" @input="fn_OnClickDetail(item, d)" :disabled="item.DISABLED || (item.CHOICETYPE === '1' && item.ANSWER_YN !== 'Y') || isReadOnly">{{d.label}}</mo-checkbox>
                                <mo-text-field ref="focusOnInputCar" v-if="item.CHOICETYPE === '3' && d.key === 'ETC3'" @focus="fn_ControlKeyPad" v-model="carInput" class="q-multi-txt mtm6 ml10 txt-left" underline placeholder="기타 차종을 입력해주세요" :disabled="item.DISABLED || isReadOnly" maxlength="8"/>
                              </div>
                            </ur-box-container>
                            <ur-box-container alignV="start" componentid="" direction="column" class="q-chk-list" v-if="item.ANSWERTYPE === 'S'">
                              <div v-for="r in item.DETAILS" :key="r.key" class="q-item">
                                <mo-radio :ref="`focusOnChk${item.ID}`" v-model="item.DETAIL[0]" :value="r.key" class="radio ml0" :disabled="isReadOnly" @input="fn_OnClickDetail(item, r)">{{r.label}}</mo-radio>
                              </div>
                            </ur-box-container>
                            <!-- 유효성 ERROR 메세지 START -->
                            <!-- <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0 mt5" v-show="item.isErrorMsg2">
                              <div class="ns-certify-sed error">
                                <div class="ns-certify-sed-txt">
                                  <div class="txt-error">하나 이상 선택해주세요.</div>
                                </div>
                              </div>
                            </ur-box-container> -->
                            <!-- 확인 버튼 START -->
                            <ur-box-container alignV="start" componentid=""  direction="column" class="mt20" v-show="item.CHOICETYPE !== '2'">
                              <div class="ns-btn-relative-s full mb0">
                                <div class="relative-div mb0">
                                  <mo-button :ref="`confirmBtn${item.ID}`" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ConfirmAnswer(item)" :disabled="item.DISABLED || isReadOnly">확인</mo-button>
                                </div>
                              </div>
                            </ur-box-container>
                          </ur-box-container>
                        </div>
                      </template>
                    </msp-expand>
                    <!-- EXPAND 닫았을 때 결과 TEXT START -->
                    <div class="q-result" v-show="item.isVisible">
                      <div class="fexTy5" v-if="item.CHOICETYPE !== '3'">
                        <mo-icon icon="msp-is-checked" icon-size="27px" />
                        <span class="ml4 fs17rem">{{item.ANSWER_YN === 'Y'? '예' : '아니오'}}</span>                        
                      </div>
                      <div class="fex-clumn mt4" v-if="(item.CHOICETYPE !== '3' && item.ANSWER_YN === 'Y') || item.CHOICETYPE === '3' ">
                        <span class="crTy-blue4">{{fn_SetText(item)}}</span>                    
                      </div> 
                    </div> 
                  </div>
                </mo-list-item>
              </div>
              <!-- 입력 타입(CHOICETYPE) 4, 5-->
              <div v-else>
                <mo-list-item>
                  <div class="list-item__contents">
                    <msp-expand btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="fexTy3-1 firstdiv-full"
                      :ref="`refExpand${item.ID}`" @expanded="onExpanded($event, item)">
                      <template #title class="w100">
                        <!-- QUESTION START-->
                        <div class="q-title">
                          <b class="q-title-num">{{item.INDEX}}.</b> <span v-html="item.QUESTION"></span>
                        </div>
                      </template>
                      <template #btn>
                        <mo-button class="link-arrow down"></mo-button>
                      </template>
                      <template #content>
                        <div class="q-content">
                          <div class="text-gray-box mt20" v-if="item.SUB_QUESTION">
                            <p class="crTy-bk7 fs14rem q-box-txt">{{ item.SUB_QUESTION }}</p>
                          </div>
                          <div class="text-gray-box mt20" v-if="item.SUB_QUESTION || item.DEFINITION !== ''">
                            <ul class="terms-list-area crTy-bk7 fs14rem mt10" v-if="item.DEFINITION !== ''">
                              <li class="crTy-bk7" v-for="def in item.DEFINITION" :key="def.title">{{def.title}}{{def.content}}</li>
                            </ul>
                          </div> 
                          <!-- 예/아니오 버튼 START -->
                          <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment mt20" v-if="item.CHOICETYPE === '4'">
                            <mo-segment-wrapper solid primary class="chip-type-wrap chip-ty1" @input="fn_setAnswerYesNoBef(item)" v-model="item.ANSWER_YN" :ref="`focusOn${item.ID}`" :disabled="isReadOnly">
                              <mo-segment-button value="Y">예</mo-segment-button>
                              <mo-segment-button value="N">아니오</mo-segment-button>
                            </mo-segment-wrapper>
                          </ur-box-container>
                          <!-- 유효성 ERROR 메세지 START -->
                          <ur-box-container alignV="start" componentid="" direction="column" class="ns-add-area pal0"  >
                            <div class="ns-certify-sed error" v-show="item.isErrorMsg1">
                              <div class="ns-certify-sed-txt">
                                <div class="txt-error">필수 입력 값입니다.</div>
                              </div>
                            </div>
                          </ur-box-container>
                            <!-- TEXT INPUT START -->
                          <ur-box-container class="request_input_list msp-req-lst">
                            <div v-for="a in item.ANSWERS" :key="a.key">
                              <ur-box-container alignV="start" componentid="" direction="column" class="row-box mt20" v-if="!(lv_mnisdCustInfo.custGenderCd === 'M' && a.key === 'G982')">
                                <span class="label-title w115px keep-all" :class="a.must? 'must': ''" v-if="!a.sub_unit">{{a.label}}</span>
                                <span class="label-title w50px pr10" :class="a.must? 'must': ''" v-if="a.sub_unit">{{a.label}}</span>
                                <span class="label-title crTy-bk1 w75px pr10" v-if="a.sub_unit">{{a.sub_unit}}</span>
                                <div class="row-text i-inp-pr30 req-item-autofull" v-if="a.unit">
                                  <mo-decimal-field :ref="`focusOnInputNum${a.key}`" @focus="fn_ControlKeyPad" numeric class="txt-right" underline placeholder="숫자만 입력해주세요" :disabled="(item.CHOICETYPE === '4' && item.ANSWER_YN !== 'Y') || isReadOnly" :maxlength="a.maxlength" :rules="a.must ? valChkNum : null" v-model="item.ANSWER[a.key]"/>
                                  <span class="right-text">{{a.unit}}</span>
                                </div>
                                <div class="flex-1" v-if="!a.unit">
                                  <mo-text-field :ref="`focusOnInputTxt${a.key}`" @focus="fn_ControlKeyPad" class="txt-left req-item-autofull" underline placeholder="입력해주세요" :disabled="(item.CHOICETYPE === '4' && item.ANSWER_YN !== 'Y') || isReadOnly" :maxlength="a.maxlength"  :rules="a.must ? valChk : null" v-model="item.ANSWER[a.key]" />
                                </div>
                              </ur-box-container>
                            </div>
                          </ur-box-container>
                          <!-- 확인버튼 START -->
                          <ur-box-container alignV="start" componentid=""  direction="column" class="mt20">
                            <div class="ns-btn-relative-s full mb0">
                              <div class="relative-div mb0">
                                <mo-button :ref="`confirmBtn${item.ID}`" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ConfirmAnswer(item)" :disabled="item.DISABLED || isReadOnly">확인</mo-button>
                              </div>
                            </div>
                          </ur-box-container>
                        </div>
                      </template>
                    </msp-expand>
                    <!-- EXPAND 닫았을 때 결과 TEXT START -->
                    <div class="q-result" v-show="item.isVisible">
                      <div class="fexTy5" v-if="item.CHOICETYPE === '4'">
                        <mo-icon icon="msp-is-checked" icon-size="27px" />
                        <span class="ml4 fs17rem">{{item.ANSWER_YN === 'Y'? '예' : '아니오'}}</span>                        
                      </div>
                      <div class="fexTy5" v-for="a in item.ANSWERS" :key="a.key">
                        <span class="min85 mr20 fs14rem crTy-bk7" v-if="item.ANSWER[a.key]">{{a.label}}</span>
                        <span class="crTy-blue4" v-if="item.ANSWER[a.key]">{{item.ANSWER[a.key]}}</span>                        
                      </div>
                    </div> 
                  </div>
                </mo-list-item>
              </div> 
            </template>
          </mo-list>
        </div>  
      </ur-box-container> 
    </ur-box-container>
    <mo-bottom-sheet preventTouchClose ref="refCommAlert" class="ns-bottom-sheet msp-bottomSt-alert">
      <!-- content 영역 -->
      <div class="customer-info-txt txt-center">
        <p class="ment" v-html="lv_AlertMsg"></p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ConfirmAlert()">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
  </ur-box-container>
</template>
<script>
import pvmNoticeJson from '@/ui/pi/common/pvmNoticeJson.js'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Gaf from '@/ui/pi/common/Gaf'
   /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPPI802D",
    screenId: "MSPPI802D",
    components: {},
    props: {
      diseYn: '',
      nundwJdgPrgStatCd: '', // 선심사진행상태코드
      befrInfoDto: {}, // 이전 질병정보
      formId: '',
      mnisdCustInfo: {},
      isReadOnly: {
        type: Boolean,
        default: false
      }
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created () {
    },
    mounted() {
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        QUESTION_LIST: [],
        lv_diseDtlDto: [],
        lv_AlertMsg: '', // 공통 알림메시지
        lv_diseYn: '', // 불러온 질병상세 여부
        lv_isReadOnly: '', // display:none
        isReopenAlert: false,
        carInput: '',
        lv_befrInfoDto: {}, // 조회한 사전정보입력 데이터
        lv_prevBefrInfoDto: {}, // 이전 질병 불러오기로 불러온 데이터
        lv_formId: '',
        lv_mnisdCustInfo: {}, // 지급정보동의 고객정보 - 주피보험자
        lv_nundwJdgPrgStatCd: '', // 선심사진행상태코드
        pvm_notice: pvmNoticeJson.PVM_NOTICE,
        allChkNo: false,
        ZZINSR_TYP_MG_ID: '', // 보험유형관리코드
        valChkNum: [
          v => !!v || '필수입력항목입니다',
          v => Number(v) !== 0 || '필수입력항목입니다'
        ],
        valChk: [
          v => !!v || '필수입력항목입니다'
        ],
        WELL: false,
        WELLitem: null
        // ZZDSCLS_M_P_T_ID: 'CC'
      };
    },
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {
    },
    watch: {
      allChkNo(val) {
        if (val) { // 모두 아니오
          for (let i = 0; i < this.QUESTION_LIST.length; i++) {
            let choiceType = this.QUESTION_LIST[i].CHOICETYPE
            let disabled = this.QUESTION_LIST[i].DISABLED
            if ((choiceType === '1' || choiceType === '2' || choiceType === '4') && !disabled) {
              this.$set(this.QUESTION_LIST[i], 'ANSWER_YN', 'N')
              if (choiceType === '4') {
                this.QUESTION_LIST[i].ANSWERS.forEach((item) => {
                  this.QUESTION_LIST[i].ANSWER[item.key] = ''
                  if (item.inputmode === 'decimal') {
                    this.$refs[`focusOnInputNum${item.key}`][0].clearValidation()
                  } else {
                    this.$refs[`focusOnInputTxt${item.key}`][0].clearValidation()
                  }
                })
              } else {
                this.QUESTION_LIST[i].DETAIL = []
              }
              if (this.$refs[`refExpand${this.QUESTION_LIST[i].ID}`].isExpand) this.$refs[`refExpand${this.QUESTION_LIST[i].ID}`].onClickBtn() // 열려있으면 닫음
              this.fn_ShowResult(this.QUESTION_LIST[i])
            }
          }
          this.$nextTick(() => {
            this.fn_OpenNext()
          })
        }
      },
      diseYn: {
        deep: true,
        immediate: true,
        handler(inData) {
          if (inData !== null) {
            this.lv_diseYn = inData
          }
        }
      },
      nundwJdgPrgStatCd: {
        deep: true,
        immediate: true,
        handler(inData) {
          if (inData !== null) {
            this.lv_nundwJdgPrgStatCd = inData
          }
        }
      },
      befrInfoDto: {
        deep: true,
        immediate: true,
        handler(inData) {
          if (inData !== null) {
            this.lv_befrInfoDto = this.$bizUtil.cloneDeep(inData)
            this.fn_GetBindData()
          }
        }
      },
      formId: {
        deep: true,
        immediate: true,
        handler(inData) {
          if (inData !== null) {
            this.lv_formId = inData
            this.fn_GetBindData()
          }
        }
      },
      mnisdCustInfo: {
        deep: true,
        immediate: true,
        handler(inData) {
          if (inData !== null) {
            this.lv_mnisdCustInfo = this.$bizUtil.cloneDeep(inData)
            this.fn_GetBindData()
          }
        }
      }
    },
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /******************************************************************************
       * Function명 : fn_ControlKeyPad
       * 설명       : 키보드 입력 시 화면을 키보드 위로 올라오게 처리하는 함수
       ******************************************************************************/
      fn_ControlKeyPad () {
        this.$commonUtil.setHiddenPlugin(false)
      },
      fn_SetAllNo() {
        if (this.allChkNo && this.lv_diseYn === 'Y') {
          if (Gaf.getPanel('MSPPI800P')) {
            let lv_dise = this.$bizUtil.cloneDeep(Gaf.getPanel('MSPPI800P').lv_DiseDtlDto)
            let lv_case1 = lv_dise.filter((i) => i.dsclsOBJScCd === '02' || (!_.isEmpty(i.mskngYn) && i.mskngYn === 'N'))
            if (!_.isEmpty(lv_case1) && lv_case1.length > 0) {
              let msg = '입력해야하는 질병상세정보가 있는 경우 \'모두 아니오\' 선택이 불가합니다.'
              this.fn_OepnConfirm(msg)
              this.allChkNo = false
            }
          }
        }
      },
      /******************************************************************************
       * Function명 : onExpanded
       * 설명       : expand open/close
       ******************************************************************************/
      onExpanded(isExpand, item) {
        if (item.isErrorMsg1 || item.isErrorMsg2) {
          if (!isExpand) this.$refs[`refExpand${item.ID}`].onClickBtn() // expand가 닫혀있으면 open
          return
        }
        if (isExpand) { // 펼친상태
          this.$set(item, 'isVisible', false)
        } else {
          this.fn_ShowResult(item)
        }
      },
      /******************************************************************************
       * Function명 : fn_SetText
       * 설명       : 선택값 text
       ******************************************************************************/
      fn_SetText(item) {
        if (item.DETAIL?.length > 0) {
          let temp = []
          item.DETAIL.forEach(i => {
            if (i !== '12') {
              let label = item.DETAILS.filter(d => d.key === i)[0].label
              if (i === 'ETC3') {
                label = `${label}(${this.carInput})`
              }
              temp.push(label)
            }
          })
          return temp.join(',')
        } else {
          return ''
        }
      },
       /******************************************************************************
       * Function명 : fn_SetIsVisible
       * 설명       : expand 열림/닫힘에 따라 선택값 노출
       ******************************************************************************/
      fn_SetIsVisible(item) {
        if ((item.CHOICETYPE === '1' || item.CHOICETYPE === '2' || item.CHOICETYPE === '4')) {
          this.$set(item, 'isVisible', !_.isEmpty(item.ANSWER_YN))
        } else if (item.CHOICETYPE === '5') {
          let flag = false
          item.ANSWERS.forEach(i => {
            if (!_.isEmpty(item.ANSWER[i.key])) {
              flag = true
            }
          })
          this.$set(item, 'isVisible', flag)
        } else {
          if (item.ANSWERTYPE === 'M') {
            if (!item.DETAIL.includes('ETC3') && !_.isEmpty(this.carInput)) this.carInput = ''
          }
          this.$set(item, 'isVisible', item.DETAIL.length > 0)
        }
      },
       /******************************************************************************
       * Function명 : fn_ShowResult
       * 설명       : expand 열림/닫힘에 따라 선택값 노출
       ******************************************************************************/
      fn_ShowResult(item) {
        if (this.$refs[`refExpand${item.ID}`]) {
          let lv_Expand = this.$refs[`refExpand${item.ID}`].isExpand
          if (lv_Expand) { // expand닫히고 결과 보임
            this.$set(item, 'isVisible', false)
          } else { // expand 열림
            this.fn_SetIsVisible(item)
          }
        } else {
          this.fn_SetIsVisible(item)
        }
      },
       /******************************************************************************
       * Function명 : fn_countNo
       * 설명       : 모두 아니오 버튼 활성/비활성
       ******************************************************************************/
      fn_countNo() {
        let tmp = this.QUESTION_LIST.filter(i => i.ANSWER_YN === '' || i.ANSWER_YN === 'Y')
        if (tmp.length > 0) {
          this.allChkNo = false
        } else {
          this.allChkNo = true
        }
      },
      /******************************************************************************
       * Function명 : fn_GetBindData
       * 설명       : 질문 목록 세팅
       ******************************************************************************/
      fn_GetBindData () {
        console.log('MSPPI802D(선심사 질문지)fn_GetBindData: ' + this.lv_formId, this.lv_befrInfoDto)
        if (!_.isEmpty(this.lv_formId) && this.lv_befrInfoDto !== null && Object.keys(this.lv_mnisdCustInfo).length > 0) {
          // this.lv_formId = 'pvm_notice' // test
          let idx = this.pvm_notice.findIndex(item => item.ID === this.lv_formId)
          // let ZZDSCLS_M_P_T_ID = GafMainFunc.getIfbcValue('ZZDSCLS_M_P_T_ID') // 고지사항출력유형코드RD (C2:7, C1:10)
          // if (ZZDSCLS_M_P_T_ID === 'C2') this.QUESTION_LIST = this.$bizUtil.cloneDeep(this.pvm_notice[idx].QUESTIONLIST_WELL7)
          // else if (ZZDSCLS_M_P_T_ID === 'C1') this.QUESTION_LIST = this.$bizUtil.cloneDeep(this.pvm_notice[idx].QUESTIONLIST_WELL10)
          // else this.QUESTION_LIST = this.$bizUtil.cloneDeep(this.pvm_notice[idx].QUESTIONLIST_CNT)
          this.QUESTION_LIST = this.$bizUtil.cloneDeep(this.pvm_notice[idx].QUESTIONLIST_CNT)
          // let lv_Vm = this
          // let chkStr = '{{ZZDSCLS_M_P_T_ID}}'
          // if (this.QUESTION_LIST) {
          //   for(let i = 0; i < this.QUESTION_LIST.length; i++) {
          //     if (!_.isEmpty(this.QUESTION_LIST[i].QUESTION) && this.QUESTION_LIST_tmp[i].QUESTION.indexOf(chkStr) > -1) {
          //       this.QUESTION_LIST[i].QUESTION.replace(chkStr, this.ZZDSCLS_M_P_T_ID)
          //     }
          //   }
          // }
          if (this.lv_befrInfoDto.isPrev) { // 이전 질병정보 불러오기로 받은 정보
            this.lv_prevBefrInfoDto = this.lv_befrInfoDto.prevPvmnotice
          }
          this.fn_SetQuestionDisabled()
        }
      },
      /******************************************************************************
       * Function명 : fn_SetPrevAnswer
       * 설명       : 이전 답변 세팅
       ******************************************************************************/
      fn_SetPrevAnswer() {
        let inputData = {}
        if (this.lv_befrInfoDto.isPrev) {
          inputData = this.lv_prevBefrInfoDto.pvmnotice.formData[0].Input
        } else {
          inputData = this.lv_befrInfoDto.pvmnotice.formData[0].Input
        }
        // let inputData = this.pvm_testData[0].Input // TEST DATA
        if (Object.keys(inputData).length > 0) {
          let answerArr = Object.keys(inputData)
          // console.log('이전 답변 세팅', answerArr);
          for(let i = 0; i < answerArr.length; i++) {
            let keyArr = answerArr[i].split('_')
            let idx = this.fn_FindIdxById(keyArr[1])
            if (keyArr instanceof Array && keyArr.length > 2) {
              if (keyArr.indexOf('YN') > -1) {
                if (!this.QUESTION_LIST[this.fn_FindIdxById(keyArr[1])].DISABLED || this.isReadOnly) {
                  this.QUESTION_LIST[idx].ANSWER_YN = inputData[`OZ1_${keyArr[1]}_YN`]
                }
              } else if (keyArr.indexOf('DETAIL') > -1) {
                if (!this.QUESTION_LIST[this.fn_FindIdxById(keyArr[1])].DISABLED || this.isReadOnly) {
                  if (this.ZZINSR_TYP_MG_ID === 'D02' && this.QUESTION_LIST[this.fn_FindIdxById(keyArr[1])].ANSWERTYPE === 'M') {
                    let dArr = inputData[`OZ1_${keyArr[1]}_DETAIL`].split(',')
                    let lv_alert = false
                    let msg = ''
                    dArr.forEach(a => { 
                      if(a !== '10' && a!== '11') {
                        this.QUESTION_LIST[idx].DETAIL.push(a)
                      } else {
                        lv_alert = true
                        msg = '운전하지 않음, 원동기장치 자전거(전동킥보드 등)</br>선택 시 자동차사고부상보장특약(무배당)</br> 가입이 불가합니다.'
                        if (answerArr.includes(`OZ1_${keyArr[1]}_ETC3`)) this.isReopenAlert = true
                      }
                    })
                    //  보험유형관리코드='D02'(자동차사고부상보장) 알림창
                    if (lv_alert) {
                      this.fn_OepnConfirm(msg)
                    } else {
                      if (answerArr.includes(`OZ1_${keyArr[1]}_ETC3`)) {
                        msg = '기타란에 약관에서 보장하지 않는 차종(전동킥보드, 농기구 등)을 작성하시는 경우 자동차사고부상보장특약 가입 불가한 점 유의 바랍니다.'
                        this.fn_OepnConfirm(msg)
                      }
                    }
                  } else {
                    let dArr = inputData[`OZ1_${keyArr[1]}_DETAIL`].split(',')
                    dArr.forEach(a => this.QUESTION_LIST[idx].DETAIL.push(a))
                  }
                }
              } else if (keyArr[2] === 'ETC3') {
                this.carInput = inputData[`OZ1_${keyArr[1]}_${keyArr[2]}`]
                this.QUESTION_LIST[idx].DETAIL.push(keyArr[2])
              } else if (keyArr[2] !== 'ID') {
                this.QUESTION_LIST[idx].ANSWER[keyArr[2]] = inputData[`OZ1_${keyArr[1]}_${keyArr[2]}`]
              }
            } else {
              this.QUESTION_LIST.forEach((item) => {
                if (item.CHOICETYPE === '5' && Object.keys(item.ANSWER).includes(keyArr[1])) {
                  item.ANSWER[keyArr[1]] = inputData[`OZ1_${keyArr[1]}`]
                }
              })
            }
          }
          this.QUESTION_LIST.forEach(item => this.fn_ShowResult(item))
        } else { // 초기화
          this.allChkNo = false
          this.QUESTION_LIST.forEach(item => {
            if (this.$refs[`refExpand${item.ID}`]) {
              if (this.$refs[`refExpand${item.ID}`].isExpand) this.$refs[`refExpand${item.ID}`].onClickBtn() // expand가 열려 있으면 close
            }
          })
        }
        this.$nextTick(() => {
          this.fn_OpenNext()
        })
      },
      /******************************************************************************
       * Function명 : fn_SetQuestionDisabled
       * 설명       : 조건에 따른 문항 활성/비활성
       ******************************************************************************/
      fn_SetQuestionDisabled() {
        let MNISD_LTC = this.lv_befrInfoDto.pvmnotice.formData[0].MNISD_LTC
        let TTH_INSR_ENT_YN = this.lv_befrInfoDto.pvmnotice.formData[0].TTH_INSR_ENT_YN
        this.ZZINSR_TYP_MG_ID = this.lv_befrInfoDto.pvmnotice.formData[0].ZZINSR_TYP_MG_ID
        if (this.lv_formId === 'pvm_notice' && MNISD_LTC === '1') { // 장기요양상태 보험(MNISD_LTC =1) 활성화
          let idx = this.fn_FindIdxById('G06')
          this.QUESTION_LIST[idx].DISABLED = false
        } else if (String(this.lv_formId).startsWith('pvm_noticeWELL') && MNISD_LTC === '1') { // 장기요양상태 보험(MNISD_LTC =1) 활성화
          let idx = this.fn_FindIdxById('G07')
          this.QUESTION_LIST[idx].DISABLED = false
        } else if (this.lv_formId === 'pvm_noticeKID') {
          // lv_MnisdCustInfo.contvInsrdTypCd === 23 이면 태아일 때 해당 문항 활성
          if(this.lv_mnisdCustInfo.contvInsrdTypCd === '23') {
            this.QUESTION_LIST[this.fn_FindIdxById('G06')].ANSWERS[2].disabled = false
            this.QUESTION_LIST[this.fn_FindIdxById('G10')].DISABLED = false
            this.QUESTION_LIST[this.fn_FindIdxById('G11')].DISABLED = false
          } 
          if (TTH_INSR_ENT_YN === 'Y') { // 치아보험 이목구비특약 가입 시 해당 문항 활성화(*LR0235***, LR0236***,LR0237***,LR0238***,LR0239***특약 가입시 활성화)
            this.QUESTION_LIST[this.fn_FindIdxById('G07')].DISABLED = false
            this.QUESTION_LIST[this.fn_FindIdxById('G08')].DISABLED = false
            this.QUESTION_LIST[this.fn_FindIdxById('G09')].DISABLED = false
          }
          if (this.lv_mnisdCustInfo.zaStxtnDeYn === 'Y') { // 16세 이상
            this.QUESTION_LIST[this.fn_FindIdxById('G13')].DISABLED = false
          }
        } else if (this.lv_formId === 'pvm_noticeCM') { // 종합간병보험 (ASR240600865_240705)
          let DMT_INSR_ENT_YN = this.lv_befrInfoDto.pvmnotice.formData[0].DMT_INSR_ENT_YN // 치매보험가입여부
          if (DMT_INSR_ENT_YN === 'Y') { // 치매보험 (상품코드(LP0753)) 일경우 Y 로 세팅됨 / 5번 문항 비활성화
            this.QUESTION_LIST[this.fn_FindIdxById('G05')].DISABLED = true
          }
        }
        this.fn_SetPrevAnswer() // 이전 답변 세팅
      },
      /******************************************************************************
       * Function명 : fn_FindIdxById
       * 설명       : ID로 문항 INDEX찾기
       ******************************************************************************/
      fn_FindIdxById(id) {
        return this.QUESTION_LIST.findIndex(item => item.ID === id)
      },
      /******************************************************************************
       * Function명 : fn_SetOutputData
       * 설명       : output data setting
       ******************************************************************************/
      fn_SetOutputData() {
        let outObj = {}
        for (let i = 0; i < this.QUESTION_LIST.length; i++) {
          let type = this.QUESTION_LIST[i].CHOICETYPE
          let id = this.QUESTION_LIST[i].ID
          let answerYn = this.QUESTION_LIST[i].ANSWER_YN
          let key = 'OZ1_' + id + '_ID'
          let yn =  'OZ1_' + id + '_YN'
          let detail = 'OZ1_' + id + '_DETAIL'
          switch(type) {
            case '1':
              outObj[key] = id
              outObj[yn] = answerYn
              outObj[detail] = this.QUESTION_LIST[i].DETAIL.toString()
              break
            case '2':
              outObj[key] = id
              outObj[yn] = answerYn
              break
            case '3':
              outObj[key] = id
              outObj[detail] = this.QUESTION_LIST[i].DETAIL.toString()
              if (this.QUESTION_LIST[i].DETAIL.includes('ETC3')) {
                outObj['OZ1_' + id + '_ETC3'] = this.carInput
              }
              break
            case '4':
              outObj[key] = id
              outObj[yn] = answerYn
              this.QUESTION_LIST[i].ANSWERS.forEach((item) => {
                let lv_key = 'OZ1_' + id + '_' + item.key
                outObj[lv_key] = this.QUESTION_LIST[i].ANSWER[item.key]
              })
              break
            case '5':
              this.QUESTION_LIST[i].ANSWERS.forEach((item) => {
                let lv_key = 'OZ1_' + item.key
                outObj[lv_key] = this.QUESTION_LIST[i].ANSWER[item.key]
              })
              break
            default:
              break
          }
        }
        // console.log('OUTPUT DATA OBJECT => ', outObj);
        return outObj
      },
      fn_CheckAnswerYn() {
        let lv_ChkList = this.QUESTION_LIST.filter(item => item.DETAILMANDATORY && item.ANSWER_YN === 'Y')
        return (lv_ChkList.length > 0)? 'Y' : 'N'
      },
      /******************************************************************************
       * Function명 : fn_ValidationChk
       * 설명       : 
        CHOICETYPE === '1'인 경우, ANSWER_YN 가 N/Y 중 하나이면서, Y일 경우 DETAIL[] 에 값 하나 이상
        CHOICETYPE === '2'인 경우, ANSWER_YN 가 N/Y 중 하나
        CHOICETYPE === '3'인 경우, DETAIL[] 에 값 하나 이상
        CHOICETYPE === '4'인 경우, ANSWER_YN 가 N/Y 중 하나이면서, Y일 경우 ANSWER(OBJECT) 값 필수
        CHOICETYPE === '5'인 경우, ANSWERS.must 가 true 면 ANSWER(OBJECT) 값 필수
        * 
       ******************************************************************************/
      fn_ValidationChk (cd = '') {
        let flag = false
        let valChkList = this.$bizUtil.cloneDeep(this.QUESTION_LIST.filter(i => !i.DISABLED))
        let lv_nextItem = {}
        for (let i = 0; i < valChkList.length; i++) {
          let type = valChkList[i].CHOICETYPE
          let yn = valChkList[i].ANSWER_YN
          let qNum = valChkList[i].INDEX
          let dList = valChkList[i].DETAIL
          let aList = valChkList[i].ANSWERS
          let answer = valChkList[i].ANSWER
          flag = false
          let q = ''
          switch(type) {
            case '1':
              flag = (yn === '' || (yn === 'Y' && dList.length === 0))
              break
            case '2':
              flag = (yn === '')
              break
            case '3':
              if (valChkList[i]?.ANSWERTYPE === 'M') { // 차종
                let tmpDetail = dList.filter(i => i === 'ETC3') // 기타
                if ((dList.length === 0) || (tmpDetail.length > 0 && this.$bizUtil.isEmpty(this.carInput))) {
                  flag = true
                }
              } else {
                flag = (dList.length === 0)
              }
              break
            case '4':
            case '5':
              q = aList.filter((i) => {
                if (i.must) {
                  if (this.$bizUtil.isEmpty(answer[i.key]) || (i.inputmode === 'decimal' && answer[i.key] === '0')) return true
                }
              })?.[0]?.key
              if (type === '4') {
                flag = (yn === '' || (yn === 'Y' && !this.$bizUtil.isEmpty(q)))
              } else {
                flag = !this.$bizUtil.isEmpty(q)
              }
              break
            default:
              break
          }
          if (flag) {
            lv_nextItem = valChkList[i]
            if (cd !== 'next' ) {
              // let msg = this.$t('pi').EPIC004.replace('{0}', qNum)
              // this.fn_CommAlert(valChkList[i], msg, q)
              this.fn_setFocus(valChkList[i], q)
              this.$nextTick(() => {
                const Confirmbtn = this.$refs['confirmBtn' + valChkList[i].ID]
                Confirmbtn.onClick()
              })
              let returnObj = {
                valChk : !flag, 
                diseYn : this.fn_CheckAnswerYn()
              }
              return returnObj
            }
            break
          }
        }

        if (cd !== 'next') {
          if (this.fn_CheckAnswerYn() === 'N' && this.lv_diseYn === 'Y') {
            if (Gaf.getPanel('MSPPI800P')) {
              let lv_dise = this.$bizUtil.cloneDeep(Gaf.getPanel('MSPPI800P').lv_DiseDtlDto)
              let lv_case1 = lv_dise.filter((i) => i.dsclsOBJScCd === '02' || (!_.isEmpty(i.mskngYn) && i.mskngYn === 'N'))
              if (!_.isEmpty(lv_case1) && lv_case1.length > 0) {
                flag = true
                let num = ''
                if (this.lv_formId === 'pvm_notice') num = '1~6'
                if (this.lv_formId === 'pvm_noticeWELL7') num = '1~7'
                if (this.lv_formId === 'pvm_noticeWELL10') num = '1~7'
                if (this.lv_formId === 'pvm_noticeCM') num = '1~5'
                if (this.lv_formId === 'pvm_noticePEN') num = '1~3'
                if (this.lv_formId === 'pvm_noticeKID') num = '1~5, 7~11'
                let msg = `입력된 질병상세정보가 있습니다.</br>사전정보 질문 ${num}번 중에</br>해당 질병과 관련된 항목의 답변을</br>정확히 입력해 주세요.`
                this.fn_OepnConfirm(msg)
              }
            }
          }
        }
        let returnObj = {
          valChk : !flag, 
          diseYn : this.fn_CheckAnswerYn(),
          nextItem : lv_nextItem
        }
        return returnObj
      },
      /******************************************************************************
       * Function명 : fn_CommAlert
       * 설명       : alert Message
       ******************************************************************************/
      fn_CommAlert(item, msg, key  = '') {
        // console.log('ITEM,', item);
        let alertMsg = msg
        let btnSingle = true
        let preventTouchClose = true
        let title_pos_btn = '확인'
        let alertObj = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: btnSingle,
            content: alertMsg,
            title_pos_btn: title_pos_btn
          },
          listeners: {
            onPopupConfirm: async() => {
              this.$bottomModal.close(alertObj).then(() => {
                this.fn_setFocus(item, key)
                this.$nextTick(() => {
                  const Confirmbtn = this.$refs['confirmBtn' + item.ID]
                  Confirmbtn.onClick()
                })
              })
            }
          }
        },
        {
          properties: {preventTouchClose : preventTouchClose}
        })
      },
      /******************************************************************************
       * Function명 : fn_setFocus
       * 설명       : fn_setFocus
       *  type 1,2,4 일 떄는 예/아니오 버튼에 focus
          type 3일 떄는 첫 번째 checkBox에 focus
          type 5일 떄는 input text에 focus
       ******************************************************************************/
      fn_setFocus(item, key = '') {
        // console.log('set FOCUS', item.ID);
        if (!this.$refs[`refExpand${item.ID}`].isExpand) this.$refs[`refExpand${item.ID}`].onClickBtn() // expand가 닫혀있으면 open
        
        let answer = item.ANSWER
        let mode = item.ANSWERS.filter(i => i.key === key)?.[0]?.inputmode
        this.$nextTick(()=> {
          if (item.CHOICETYPE === '1' || item.CHOICETYPE === '2') {
            this.$refs[`focusOn${item.ID}`].focusOn()
          } else if (item.CHOICETYPE === '3') {
            if (item.ANSWERTYPE === 'M') { // 기타 차종 미입력시
              let tmpDetail = item.DETAIL.filter(i => i === 'ETC3')
              if (tmpDetail.length > 0 && this.$bizUtil.isEmpty(this.carInput)) {
                this.$refs.focusOnInputCar[0].focus()
              } else {
                this.$refs[`focusOnChk${item.ID}`][0].focusOn()
              }
            } else {
              this.$refs[`focusOnChk${item.ID}`][0].focusOn()
            }
          } else {
            if (item.CHOICETYPE === '4' && this.$bizUtil.isEmpty(item.ANSWER_YN)) {
              this.$refs[`focusOn${item.ID}`].focusOn()
            } else {
              if (mode === 'decimal' && Number(answer[`${key}`]) === 0) {
                this.$refs[`focusOnInputNum${key}`][0].focus()
              } else {
                this.$refs[`focusOnInputTxt${key}`][0].focus()
              }
            }
          }
        })
        this.$nextTick(() => {
          this.fn_ShowResult(item)
        })
      },
      /******************************************************************************
       * Function명 : fn_setAnswerYesNoBef
       * 설명       : 예/아니오 버튼 선택 ('아니오' 버튼일 때 값 초기화)
      ******************************************************************************/
      fn_setAnswerYesNoBef (item) {
        if (item.ANSWER_YN === 'Y' && String(this.lv_formId).startsWith('pvm_noticeWELL') && item.ID === 'G06') {
          this.WELL = true
          this.WELLitem = item
          this.fn_OepnConfirm("7년/10년 내 입원 혹은 수술 이력이 있으면\n설계 중 상품 가입이 불가능합니다.\n완화질환에 해당하는 경우 고지하지 않으셔도 됩니다.")
        } else {
          this.fn_setAnswerYesNo(item)
        }
      },
      /******************************************************************************
       * Function명 : fn_setAnswerYesNo
       * 설명       : 예/아니오 버튼 선택 ('아니오' 버튼일 때 값 초기화)
      ******************************************************************************/
      fn_setAnswerYesNo (item) {
        this.$set(item, 'isErrorMsg1', false)
        if (item.ANSWER_YN === 'Y') {
          this.allChkNo = false
          let lv_disabled = []
          if (!_.isEmpty(item.DETAILS)) {
            lv_disabled = item.DETAILS.filter(i => i.disabled)
          }
          // 어린이보험(pvm_noticeKID)일 때
          if (this.lv_formId === 'pvm_noticeKID' && (item.ID === 'G07' || item.ID === 'G09')) {
            this.getStore('toast').dispatch('ADD', this.$t('pi')['EPIC001'])
            this.$set(item, 'ANSWER_YN', '')
            return
          } else if (item.CHOICETYPE === '1' && (item.DETAILS.length === lv_disabled.length)) {
            this.getStore('toast').dispatch('ADD', this.$t('pi')['EPIC001'])
            this.$set(item, 'ANSWER_YN', '')
          }
          if (item.CHOICETYPE === '2') this.fn_MoveNextQuestion(item)
        } else {
          if (item.CHOICETYPE === '4') {
            item.ANSWERS.forEach((i) => {
              item.ANSWER[i.key] = ''
              if (i.inputmode === 'decimal') {
                this.$refs[`focusOnInputNum${i.key}`][0].clearValidation()
              } else {
                this.$refs[`focusOnInputTxt${i.key}`][0].clearValidation()
              }
            })
          } else {
            item.DETAIL = []
          }
          this.$set(item, 'isErrorMsg2', false)
          this.fn_MoveNextQuestion(item)
        }
      },
      /******************************************************************************
       * Function명 : fn_OnClickDetail
       * 설명       : 목록 선택값에 따른 처리
      ******************************************************************************/
      fn_OnClickDetail(item, d) {
        // console.log(item.DETAIL);
        let msg = ''
        // disabled : 진행 불가
        if (d.disabled) {
          if (this.lv_formId === 'pvm_noticeKID' && item.ID === 'G10') {
            msg = 'EPIC002' // 태아보험 가입불가 조건입니다.
          } else {
            msg = 'EPIC001' // 가입이 불가능한 병력/질환을 선택하셨습니다.
          }
          this.getStore('toast').dispatch('ADD', this.$t('pi')[msg])
          item.DETAIL.pop()
          return
        }
        if (item.ANSWERTYPE === 'M') {
          if (this.ZZINSR_TYP_MG_ID === 'D02') {
            let lv_detail = item.DETAIL.filter(i => i === 'ETC3')
            let lv_detail2 = item.DETAIL.filter(i => i === '11' || i === '10')
            if (lv_detail2.length > 0) {
              let msg = '운전하지 않음, 원동기장치 자전거(전동킥보드 등)</br>선택 시 자동차사고부상보장특약(무배당)</br> 가입이 불가합니다.'
              this.fn_OepnConfirm(msg)
              item.DETAIL.pop()
              return
            } else if (lv_detail.length > 0 && d.key === 'ETC3') {
              let msg = '기타란에 약관에서 보장하지 않는 차종(전동킥보드, 농기구 등)을 작성하시는 경우 자동차사고부상보장특약 가입 불가한 점 유의 바랍니다.'
              this.fn_OepnConfirm(msg)
            }
          }
          let detail = item.DETAIL.filter(i => i !== '11')
          let detail2 = item.DETAIL.filter(i => i === '11')
          if ((d.key === '11' && detail.length > 0) || (d.key !== '11' && detail2.length > 0)) {
            if (d.key === '11' && detail.length > 0) this.getStore('toast').dispatch('ADD', this.$t('pi')['EPIC003']) // 운전하지 않음을 선택하신 경우, 다른 차종을 선택할 수 없습니다.
            let cnt = item.DETAIL.length
            for (let i = 0; i < cnt; i++) {
              item.DETAIL.pop()
            }
            item.DETAIL.push(d.key)
          }
        }
        if (item.DETAIL.length > 0 )this.$set(item, 'isErrorMsg2', false)
      },
      /******************************************************************************
       * Function명 : fn_CheckQuestionVal
       * 설명       : input 입력값의 validation check
      ******************************************************************************/
      fn_CheckQuestionVal(item) {
        let flag = false
        let answers = item.ANSWERS
        for (let i = 0; i < answers.length; i++) {
          if (answers[i].must) {
            if (this.$bizUtil.isEmpty(item.ANSWER[answers[i].key]) || (answers[i].inputmode === 'decimal' && item.ANSWER[answers[i].key] === '0')) {
              if (item.CHOICETYPE !== '4') this.$set(item, 'isErrorMsg1', true)
              flag = true
              if (answers[i].inputmode === 'decimal') {
                this.$refs[`focusOnInputNum${answers[i].key}`][0].validate()
                this.$refs[`focusOnInputNum${answers[i].key}`][0].focus()
              } else {
                this.$refs[`focusOnInputTxt${answers[i].key}`][0].validate()
                this.$refs[`focusOnInputTxt${answers[i].key}`][0].focus()
              }
              break
            }
          }
        }
        return flag
      },
      /******************************************************************************
       * Function명 : fn_ConfirmAnswer
       * 설명       : '확인'버튼 (문항에 대한 validation cehck후, 현재 문항 닫고 다음 문항 이동)
      ******************************************************************************/
      fn_ConfirmAnswer(item) {
        this.$set(item, 'isErrorMsg1', false)
        this.$set(item, 'isErrorMsg2', false)
        if (item.CHOICETYPE === '3' || item.CHOICETYPE === '5') {
          if (item.CHOICETYPE === '5') {
            if (this.fn_CheckQuestionVal(item)) return
          } else {
            if (item.DETAIL.length === 0) {
              this.$set(item, 'isErrorMsg2', true)
              return
            } else { // 차종 기타 입력 안 했을 때,
              if (item.ANSWERTYPE === 'M') { // 차종
                let tmpDetail = item.DETAIL.filter(i => i === 'ETC3') // 기타
                if (tmpDetail.length > 0 && this.$bizUtil.isEmpty(this.carInput)) {
                  this.$set(item, 'isErrorMsg2', true)
                  return
                }
              }
            }
          }
          this.fn_MoveNextQuestion(item)
        } else {
          if (_.isEmpty(item.ANSWER_YN)) { // 예/아니오 값 없음
            this.$set(item, 'isErrorMsg1', true)
            return
          }
          if (item.ANSWER_YN === 'Y') { // '예'인데 선택 or 입력 값 없음
            if (item.CHOICETYPE === '1' && item.DETAIL.length === 0) {
              this.$set(item, 'isErrorMsg2', true)
              return
            } else { // CHOICETYPE = 4
              if (this.fn_CheckQuestionVal(item)) return
            }
          }
          this.fn_MoveNextQuestion(item)
        }
      },
      /******************************************************************************
       * Function명 : fn_OpenNext
       * 설명       : 펼쳐야 되는 문항
      ******************************************************************************/
      fn_OpenNext() {
        let isValInfo = this.fn_ValidationChk('next')
        
        if (!isValInfo.valChk) {
          let q = ''
          if (isValInfo.nextItem.CHOICETYPE === '4' || isValInfo.nextItem.CHOICETYPE === '5') {
            let lv_q = isValInfo.nextItem.ANSWERS.filter((i) => {
              if (i.must && _.isEmpty(isValInfo.nextItem.ANSWER[i.key]) || (i.inputmode === 'decimal' && isValInfo.nextItem.ANSWER[i.key] === '0')) return true
            })
            if (lv_q.length > 0) {
              q = lv_q[0].key
              this.$nextTick(()=> {
                this.fn_setFocus(isValInfo.nextItem, q)
              })
            }
          } else {
            // console.log('펼쳐야 되는 문항', isValInfo.nextItem.ID);
            this.$nextTick(()=> {
              this.fn_setFocus(isValInfo.nextItem, q)
            })
          }
        }
      },
      /******************************************************************************
       * Function명 : fn_MoveNextQuestion
       * 설명       : 현재 문항 닫고 다음 문항 이동
      ******************************************************************************/
      fn_MoveNextQuestion(item) {
        if (this.$refs[`refExpand${item.ID}`]) {
          if (this.$refs[`refExpand${item.ID}`].isExpand) this.$refs[`refExpand${item.ID}`].onClickBtn()
          if (this.allChkNo) { // '모두 아니오'일 때 결과 text보이기
            this.fn_ShowResult(item)
          }
          // 다음 문항 이동
          if (item.ID !== this.QUESTION_LIST[this.QUESTION_LIST.length - 1].ID) {
            this.fn_OpenNext()
          }
        }
      },
      /******************************************************************************
      * Function명 : fn_OepnConfirm
      * 설명       : 알럿메시지 공통 바텀시트 노출
      * @param : msg
      ******************************************************************************/
      fn_OepnConfirm(msg) {
        if (_.isEmpty(msg)) return
        this.lv_AlertMsg = msg
        this.$refs.refCommAlert.open()
      },
      fn_ConfirmAlert() {
        this.$refs.refCommAlert.close()
        if (this.isReopenAlert) {
          this.lv_AlertMsg = '기타란에 약관에서 보장하지 않는 차종(전동킥보드, 농기구 등)을 작성하시는 경우 자동차사고부상보장특약 가입 불가한 점 유의 바랍니다.'
          this.$refs.refCommAlert.open()
          this.isReopenAlert = false
        }
        if (this.WELL) {
          this.fn_setAnswerYesNo(this.WELLitem)
          this.WELL = false
          this.WELLitem = null
        }
      }
    }
  }
</script>